exports.__esModule = true;

const _extends =
  Object.assign ||
  function (target) {
    for (let i = 1; i < arguments.length; i++) {
      const source = arguments[i];
      for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

exports.default = createLink;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function _objectWithoutProperties(obj, keys) {
  const target = {};
  for (const i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      `Super expression must either be null or a function, not ${typeof superClass}`
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

const _PropTypes = require('prop-types');
const _actions = require('../actions');

const _utilsUrlUtils = require('../utils/urlUtils');

const _Router = require('../Router');

const _Router2 = _interopRequireDefault(_Router);

function createLink(React, connect) {
  const { Component } = React;
  const PropTypes = _PropTypes;

  const Link = (function (_Component) {
    _inherits(Link, _Component);

    function Link() {
      _classCallCheck(this, Link);

      _Component.apply(this, arguments);
    }

    Link.prototype.shouldComponentUpdate = function shouldComponentUpdate(
      nextProps
    ) {
      return nextProps.router.state === 'TRANSITIONED';
    };

    Link.prototype.handleClick = function handleClick(e) {
      e.preventDefault();

      this.context.store.dispatch(
        _actions.transitionTo(this.props.to, this.props.query)
      );
    };

    Link.prototype.render = function render() {
      const _props = this.props;
      const { to } = _props;
      const { query } = _props;
      const { router } = _props;
      const { stateProps } = _props;

      const props = _objectWithoutProperties(_props, [
        'to',
        'query',
        'router',
        'stateProps',
        'dispatch'
      ]);

      const href = _utilsUrlUtils.createHref(to, query);
      const { state } = router;
      const { route } = router;

      let newProps = props;

      if (state === 'TRANSITIONED' && stateProps && route) {
        let matches = href === route.pathname;

        if (!matches) {
          if (href === '/') {
            matches = true;
          } else if (href.length < route.pathname.length) {
            matches = new RegExp(`^(${href}|${href}/.*)$`).test(route.pathname);
          }
        }

        newProps = _extends(
          {},
          props,
          stateProps[matches ? 'active' : 'inactive'] || {}
        );
      }

      return React.createElement(
        'a',
        _extends(
          {
            href
          },
          newProps,
          {
            onClick: this.handleClick.bind(this)
          }
        ),
        this.props.children
      );
    };

    return Link;
  })(Component);

  Link.propTypes = {
    to: PropTypes.string.isRequired,
    query: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
    stateProps: PropTypes.shape({
      active: PropTypes.object,
      inactive: PropTypes.object
    }),
    router: PropTypes.object.isRequired
  };

  Link.contextTypes = {
    store: PropTypes.shape({
      dispatch: PropTypes.func.isRequired,
      router: PropTypes.instanceOf(_Router2.default).isRequired
    }).isRequired
  };

  return connect(function (state) {
    return {
      router: state.router
    };
  })(Link);
}

module.exports = exports.default;
