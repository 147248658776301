// Vendor
import React, { PropTypes } from 'react';
import classNames from 'classnames';

// Component
import { Container } from './../';

const Header = (props) => ((
    <div className={classNames('header', props.size)}>
        <Container relative>
            <div className={classNames('left-component', props.loggedIn && 'logged-in')}>
                {props.left}
            </div>
            <div className="right-component">
                {props.right}
            </div>
        </Container>
    </div>
));

// Prop types
Header.propTypes = {
    size: PropTypes.string,
    left: PropTypes.any,
    right: PropTypes.any,
    loggedIn: PropTypes.bool
};

export default Header;
