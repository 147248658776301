import React, { Component, PropTypes } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Modal } from "./../../../../../components";
import { EditCourseForm } from "./../../../../../forms/admin";

import {
  createCourse,
  editCourse,
  getCourses,
} from "./../../../../../actions/user";

class EditCourseModal extends Component {
  handleSubmit(data) {
    const { createCourse, getCourses, editCourse, courseId } = this.props;
    const { removeModal } = this.context;

    if (courseId == null) {
      createCourse(data).then((action) => {
        if (!action.error) {
          getCourses();
          removeModal();
        }
      });
    } else {
      editCourse(courseId, data).then((action) => {
        if (!action.error) {
          removeModal();
        }
      });
    }
  }

  render() {
    const { courseId, courses } = this.props;
    const { removeModal } = this.context;

    return (
      <Modal onClose={removeModal} large>
        <div style={{ margin: "3em 0 1em 0" }}>
          <EditCourseForm
            courseId={courseId}
            onSubmit={this.handleSubmit.bind(this)}
            courses={courses}
          />
        </div>
      </Modal>
    );
  }
}

EditCourseModal.propTypes = {
  editCourse: PropTypes.func.isRequired,
  courseId: PropTypes.number.isRequired,
  courses: PropTypes.object.isRequired,
  createCourse: PropTypes.func.isRequired,
  getCourses: PropTypes.func.isRequired,
};

EditCourseModal.contextTypes = {
  removeModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    courses: state.users.get("adminCourses"),
  }),
  (dispatch) =>
    bindActionCreators({ createCourse, editCourse, getCourses }, dispatch)
)(EditCourseModal);
