import React, { Component, PropTypes } from "react";
import classNames from "classnames";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";

import { Button, EditButton, Graph, Modal } from "./../../../../../components";
import { ProgressBlock, RowBreak, QuizModal } from "./../";
import { QuizForm } from "./../../../../../forms/user";
import { EditSectionModal, NoticeModal } from "./components";

import { scrollTo } from "./../../../../../utilities";
import {
  fetchCourse,
  fetchCourseAdmin,
  evaluateQuiz,
  fetchUser,
  resetQuiz,
} from "./../../../../../actions/user";
import { isBba, normalizeError } from "./../../../../../helpers";
import ConfirmModal from "../ConfirmModal";

const tableData = [
  { info: "name", key: "THEME_NAME" },
  { info: "adviser", key: "LECTOR" },
  { info: "place", key: "PLACE" },
  { info: "date", key: "DATE", date: true },
];

const graphData = isBba()
  ? [
      {
        key: "FULFILLED_ASSIGNMENT",
        color: "#8ac3d0",
        edit: false,
        sectionId: 1,
      },
      { key: "PARTICIPATION", color: "#de629a", edit: true, sectionId: 2 },
      { key: "ACTIVITY", color: "#86a83e", edit: true, sectionId: 3 },
    ]
  : [
      {
        key: "FULFILLED_ASSIGNMENT",
        color: "#8ac3d0",
        edit: false,
        sectionId: 1,
      },
      { key: "PARTICIPATION", color: "#de629a", edit: true, sectionId: 2 },
      { key: "ACTIVITY", color: "#86a83e", edit: true, sectionId: 3 },
      { key: "QUIZ", color: "#d29747", edit: false, sectionId: 4 },
    ];

class MainTable extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    let courses = [];
    let selectedCourse = -1;

    if (user && user.get("courses") && user.get("courses").size > 0) {
      courses = user.get("courses");
      if (user.get("selected_course")) {
        selectedCourse = courses
          .find(
            (course) => course.get("course_id") === user.get("selected_course")
          )
          .get("course_id");
      }
    }

    this.state = {
      activeTab: selectedCourse.toString(),
      enabled: courses.map((course) => {
        if (course.active === true) {
          return course.order.toString();
        }
        return false;
      }),
      activeCourse: this.props.courses.get(selectedCourse),
      time: 600,
      expandedAnswers: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const courseId = this.getSelectedCourseId.bind(this)();

    if (courseId) {
      this.fetchCourse.bind(this, courseId)();
    }
  }

  componentWillReceiveProps(nextProps) {
    const courseId = this.getSelectedCourseId.bind(this)();

    this.setState({
      activeCourse: nextProps.courses.get(courseId),
    });
  }

  getSelectedCourseId() {
    const { activeTab } = this.state;

    if (activeTab !== "-1") {
      const { user } = this.props;
      const courses = user.get("courses");

      return courses
        .find((course) => course.get("course_id") === Number(activeTab))
        .get("course_id");
    }

    return false;
  }

  fetchCourse(id) {
    const { role, fetchCourse, fetchCourseAdmin, user } = this.props;

    if (id !== "-1") {
      if (user) {
        if (role === "user") {
          fetchCourse(Number(id));
        } else {
          fetchCourseAdmin(user.get("id"), Number(id));
        }
      }
    }
  }

  submitQuizForm() {
    this.refs.quizForm.submit();
    clearInterval(this.interval);
    this.interval = null;
    this.setState({ time: 600 });
  }

  handleSubmit(data) {
    const { addModal, removeModal } = this.context;
    const { evaluateQuiz, courses, fetchUser } = this.props;
    const id = Number(this.getSelectedCourseId.bind(this)());
    const activeCourse = courses.get(id);

    evaluateQuiz(activeCourse.get("course_id"), data).then((action) => {
      if (!action.error) {
        this.scrollToElement("mainTable");
        this.fetchCourse.bind(this, id)();
        fetchUser();
        addModal(
          <Modal onClose={removeModal}>
            <div style={{ textAlign: "center" }}>
              <h2>
                <FormattedMessage id="QUIZ_RESULTS" />
              </h2>
              <p>
                <FormattedMessage id="QUESTIONS_COUNT" />:{" "}
                {action.result.body.answers} <br />
                <FormattedMessage id="CORRECT_ANSWERS" />:{" "}
                {action.result.body.correct}
              </p>

              <div style={{ marginTop: "2em" }}>
                <Button onClick={removeModal}>
                  <FormattedMessage id="CLOSE" />
                </Button>
              </div>
            </div>
          </Modal>
        );
      }
    });
  }

  activeTab(tab, courseId) {
    const { time } = this.state;

    this.scrollToElement("mainTable");
    this.fetchCourse.bind(this, courseId)();
    this.setState({ activeTab: courseId });

    if (time < 600) {
      this.submitQuizForm.bind(this)();
    }
  }

  scrollToElement(item) {
    scrollTo(this.refs[item], 3000, 10);
  }

  startTimer() {
    this.setState({ time: 600 });
    this.interval = setInterval(() => {
      this.setState((prevState) => ({ time: prevState.time - 1 }));
    }, 1000);
  }

  startQuiz(courseId) {
    const { addModal } = this.context;

    addModal(
      <QuizModal
        courseId={courseId}
        scrollTo={this.scrollToElement.bind(this, "quiz")}
        onSubmit={this.startTimer.bind(this)}
      />
    );
  }

  showEditModal(userId, courseId, sectionId, heading) {
    const { addModal } = this.context;

    addModal(
      <EditSectionModal
        heading={heading}
        userId={userId}
        courseId={courseId}
        sectionId={sectionId}
      />
    );
  }

  showNoticeModal(userId, courseId) {
    const { addModal } = this.context;

    addModal(<NoticeModal userId={userId} courseId={courseId} />);
  }

  resetQuiz = (courseId, userId) => {
    const { resetQuiz } = this.props;
    const { removeModal } = this.context;

    resetQuiz(courseId, userId).then((action) => {
      removeModal();

      if (!action.error) {
        this.fetchCourse.bind(this, courseId)();
      }
    });
  };

  render() {
    const { activeTab, activeCourse, time, expandedAnswers } = this.state;
    const { addModal } = this.context;
    const {
      role,
      user,
      loggedUser,
      userLoaded,
      coursesLoading,
      coursesError,
      quizError,
    } = this.props;
    let isForSuperior = false;
    let value = 0;
    let max = 0;

    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;

    if (activeTab === "-1") {
      if (role === "user") {
        return (
          <div className="main-table__content">
            <FormattedMessage id="NO_CURSES" />
          </div>
        );
      }

      if (user) {
        return (
          <div className="main-table__content">
            <FormattedMessage id="USER_NOT_IN_COURSE" />
          </div>
        );
      }

      return (
        <div className="main-table__content">
          <FormattedMessage id="LOADING" />
        </div>
      );
    }

    if (!userLoaded || !user) {
      return <FormattedMessage id="LOADING" />;
    }

    if (role === "superior") {
      if (
        user.get("superior") &&
        user.get("superior").get("id") === loggedUser.get("id")
      ) {
        isForSuperior = true;
      }
    }

    if (user && user.get("graph")) {
      user.get("graph").map((item) => {
        value += item.get("value");
        max += item.get("maximum");

        return true;
      });
    }

    if (time === 0) {
      this.submitQuizForm.bind(this)();
    }

    const userCourses = user.get("courses").sortBy((a) => a.get("order"));
    const courseId = this.getSelectedCourseId.bind(this)();

    return (
      <div>
        {this.interval && (
          <div
            className={classNames(
              "quiz-remaining-time",
              time < 60 && "low-time"
            )}
          >
            {`${minutes}m  ${seconds}s`}
          </div>
        )}
        <ProgressBlock activeTab={activeTab} />

        <div className={classNames("info-table__progress-info", "mobile")}>
          <span className="large">
            <FormattedMessage id="TOTAL_SCORE" />
          </span>
          <span className="main">{value} b</span>
          <span className="small">z {max} b</span>
        </div>

        <RowBreak black>
          <FormattedMessage id="DEVELOPMENT_DETAIL" />
        </RowBreak>

        <div className="main-table" ref="mainTable">
          {/* NAVIGATION */}
          <div className="main-table__navigation">
            <ul>
              {userCourses.map((course, index) => {
                const courseName =
                  coursesLoading.get(courseId) &&
                  courseId === course.get("course_id") ? (
                    <FormattedMessage id="LOADING" />
                  ) : (
                    course && course.get("name")
                  );
                const title = courseName;
                const tab = course.get("order");

                return (
                  <li
                    key={index}
                    className={classNames(
                      Number(activeTab) === course.get("course_id") && "active",
                      course.get("started") &&
                        user.get("selected_course") !== Number(tab) &&
                        "passed"
                    )}
                    style={{
                      width:
                        window.innerWidth > 1023
                          ? index + 1 === userCourses.size
                            ? `calc(100% / ${userCourses.size})`
                            : `calc((100% / ${userCourses.size}) - 6px)`
                          : `calc((100% / ${userCourses.size / 2}))`,
                    }}
                    onClick={this.activeTab.bind(
                      this,
                      tab,
                      course.get("course_id")
                    )}
                    title={title}
                  >
                    <span>{title}</span>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="main-table__content">
            {/* BASE COURSE INFO */}
            {coursesLoading.get(courseId) || !activeCourse ? (
              <div style={{ marginTop: "30px" }}>
                <FormattedMessage id="LOADING" />
              </div>
            ) : (
              <div>
                <div className="main-table__content__block">
                  <div className="graph-block">
                    <table className="graph-table">
                      {graphData.map((item, i) => (
                        <tr key={i}>
                          <td>
                            <span
                              className="preview"
                              style={{ backgroundColor: item.color }}
                            />
                          </td>
                          <td>
                            <span className="about">
                              <FormattedMessage id={item.key} />
                            </span>
                          </td>
                          {role === "lector" &&
                            item.edit &&
                            activeCourse.get("started") && (
                              <td>
                                <EditButton
                                  onClick={this.showEditModal.bind(
                                    this,
                                    user.get("id"),
                                    activeCourse.get("course_id"),
                                    item.sectionId,
                                    item.key
                                  )}
                                />
                              </td>
                            )}
                          {(isForSuperior || role === "lector") &&
                            activeCourse.get("started") &&
                            i === 0 && (
                              <td>
                                <EditButton
                                  onClick={this.showEditModal.bind(
                                    this,
                                    user.get("id"),
                                    activeCourse.get("course_id"),
                                    1,
                                    "FULFILLED_ASSIGNMENT"
                                  )}
                                />
                              </td>
                            )}
                        </tr>
                      ))}
                    </table>
                    <Graph
                      disabled={!activeCourse.get("started")}
                      graphData={activeCourse.get("graph_sections")}
                    />
                  </div>

                  <table className="info-table">
                    <tbody>
                      {tableData.map((item, i) => (
                        <tr key={i}>
                          <td>
                            {item.date
                              ? moment(activeCourse.get(item.info)).format(
                                  "DD.MM.YYYY"
                                )
                              : activeCourse.get(item.info)}
                          </td>
                          <td>
                            <FormattedMessage id={item.key} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* ASSIGNMENT BLOCK */}
                <div style={{ marginTop: "3em" }}>
                  <RowBreak>
                    <FormattedMessage id="ASSIGNMENT" />
                  </RowBreak>
                  <div className="main-table__content__block">
                    <p>
                      {activeCourse.get("task") ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: activeCourse.get("task"),
                          }}
                        />
                      ) : (
                        <FormattedMessage id="ASSIGNMENT_INFO" />
                      )}
                    </p>
                  </div>
                </div>

                {/* QUIZ BLOCK */}
                <div className="quiz-block">
                  <RowBreak>
                    <FormattedMessage id="QUIZ" />
                  </RowBreak>
                  <div className="main-table__content__block">
                    {!activeCourse.get("quiz_generated") ? (
                      <div className="about">
                        <p>
                          <FormattedMessage id="QUIZ_BLOCK_INFO" />
                        </p>
                        {loggedUser.get("role") === "user" && (
                          <Button
                            className="quiz-button"
                            disabled={
                              activeCourse.get("quiz") ||
                              !activeCourse.get("started")
                            }
                            onClick={this.startQuiz.bind(
                              this,
                              activeCourse.get("course_id")
                            )}
                          >
                            <FormattedMessage id="START_QUIZ" />
                          </Button>
                        )}
                        {coursesError.get("quiz") &&
                          coursesError
                            .get("quiz")
                            .get(activeCourse.get("course_id")) && (
                            <div className="error">
                              <FormattedMessage
                                id={`ERRORS.${normalizeError(
                                  coursesError
                                    .get("quiz")
                                    .get(activeCourse.get("course_id"))
                                    .get("error_code")
                                )}`}
                              />
                            </div>
                          )}
                      </div>
                    ) : (
                      <div>
                        <p className="quiz-correct-info">
                          <FormattedMessage id="COUNT_OF_CORRECT_ANSWERS" />
                          <span className="answers">
                            <span>
                              {activeCourse.get("quiz_correct_answers")} / 20{" "}
                            </span>
                            <br />
                            {activeCourse.has("answers") ? (
                              <Button
                                onClick={() =>
                                  this.setState({
                                    expandedAnswers: !expandedAnswers,
                                  })
                                }
                              >
                                <FormattedMessage
                                  id={
                                    expandedAnswers
                                      ? "HIDE_ANSWERS"
                                      : "VIEW_ANSWERS"
                                  }
                                />
                              </Button>
                            ) : null}
                            {role === "lector" ||
                            (isBba() && role === "user") ? (
                              <Button
                                className="danger"
                                onClick={() => {
                                  addModal(
                                    <ConfirmModal
                                      {...{
                                        user,
                                        onSubmit: () => {
                                          if (role === "lector") {
                                            this.resetQuiz(
                                              activeCourse.get("course_id"),
                                              user.get("id")
                                            );
                                          } else {
                                            this.resetQuiz(
                                              activeCourse.get("course_id")
                                            );
                                          }
                                        },
                                      }}
                                    />
                                  );
                                }}
                              >
                                <FormattedMessage id="RESET" />
                              </Button>
                            ) : null}
                          </span>
                        </p>
                        <div
                          className={classNames(
                            "expand-row",
                            expandedAnswers && "expanded"
                          )}
                        >
                          {activeCourse.has("answers")
                            ? activeCourse
                                .get("answers")
                                .map((answer, questionKey) => (
                                  <div className="question-preview">
                                    <p>
                                      {questionKey + 1}.{" "}
                                      {answer.getIn(["question", "text"])}
                                    </p>
                                    <div>
                                      {answer
                                        .getIn(["question", "answers"])
                                        .map((currentAnswer, answerKey) => (
                                          <div
                                            key={`${questionKey}-${answerKey}`}
                                          >
                                            <input
                                              type="radio"
                                              checked={
                                                answer.get("answer_id") !=
                                                  null &&
                                                answer.get("answer_id") ===
                                                  currentAnswer.get("id")
                                              }
                                            />
                                            <p
                                              className={classNames(
                                                currentAnswer.get(
                                                  "is_correct"
                                                ) && "correct"
                                              )}
                                            >
                                              {currentAnswer.get("text")}
                                            </p>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                ))
                            : null}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {!activeCourse.get("quiz_generated") &&
                  activeCourse.get("quiz") && (
                    <div ref="quiz">
                      <RowBreak />

                      <div className="main-table__content__block">
                        <QuizForm
                          questions={activeCourse.get("quiz")}
                          onSubmit={this.handleSubmit.bind(this)}
                          ref="quizForm"
                        />
                      </div>

                      <RowBreak />

                      <div className="main-table__content__block">
                        <div style={{ textAlign: "right" }}>
                          <Button
                            type="submit"
                            onClick={this.submitQuizForm.bind(this)}
                          >
                            <FormattedMessage id="EVALUATE" />
                          </Button>
                        </div>
                        {quizError.has(activeCourse.get("course_id")) && (
                          <span className="error">
                            {quizError
                              .get(activeCourse.get("course_id"))
                              .toString()}
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                {(role === "lector" || isForSuperior) &&
                  activeCourse.get("started") && (
                    <div>
                      <RowBreak>
                        <FormattedMessage id="LECTOR_EVALUATION" />
                      </RowBreak>
                      <div className="main-table__content__block">
                        {activeCourse.get("notice") ? (
                          <p style={{ paddingRight: "2.3em" }}>
                            {activeCourse.get("notice")}
                          </p>
                        ) : (
                          <p>
                            <FormattedMessage id="NOTICE_INFO" />
                          </p>
                        )}
                        {role === "lector" && (
                          <EditButton
                            className="edit-button"
                            onClick={this.showNoticeModal.bind(
                              this,
                              user.get("id"),
                              activeCourse.get("course_id")
                            )}
                          />
                        )}
                      </div>
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

MainTable.propTypes = {
  role: PropTypes.string.isRequired,
  loggedUser: PropTypes.object,
  user: PropTypes.object.isRequired,
  userLoading: PropTypes.bool,
  userLoaded: PropTypes.bool,
  fetchCourse: PropTypes.func.isRequired,
  fetchCourseAdmin: PropTypes.func.isRequired,
  courses: PropTypes.object.isRequired,
  coursesLoading: PropTypes.object,
  coursesError: PropTypes.object,
  fetchQuiz: PropTypes.func.isRequired,
  evaluateQuiz: PropTypes.func.isRequired,
  quizError: PropTypes.object,
  fetchUser: PropTypes.func.isRequired,
};

MainTable.contextTypes = {
  addModal: PropTypes.func.isRequired,
  removeModal: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    role: state.users.get("user").get("role"),
    loggedUser: state.users.get("user"),
    user:
      state.users.get("user").get("role") === "user"
        ? state.users.get("user")
        : state.users.getIn(["users", Number(state.router.route.vars.id)]), // eslint-disable-line
    userLoading: state.users.get("userLoading"),
    userLoaded: state.users.get("userLoaded"),
    coursesLoading: state.users.get("coursesLoading"),
    coursesError: state.users.get("coursesError"),
    quizError: state.users.get("quizError"),
    courses: state.users.get("courses"),
  }),
  (dispatch) =>
    bindActionCreators(
      { fetchCourse, fetchCourseAdmin, fetchUser, evaluateQuiz, resetQuiz },
      dispatch
    )
)(MainTable);
