/* eslint-disable */
import React, { PropTypes } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Map } from "immutable";

import { ProgressBar } from "./../../../../../components";

const ProgressBlock = (props) => {
  if (props.table && props.admin) {
    return (
      <div className={classNames("progress-in-row", props.table && "small")}>
        {props.courses
          .sortBy((course) => course.get("order"))
          .map((course, i) => {
            const graph =
              props.graph.find(
                (g) => g.get("course_id") === course.get("course_id")
              ) || Map();

            return (
              <ProgressBar
                table={props.table}
                active={Number(props.activeTab) === i + 1}
                disabled={false}
                progress={graph.get("value") || 0}
                maximum={graph.get("maximum") || 0}
                number={course.get("order")}
                key={i}
                courses={props.courses.sortBy((course) => course.get("order"))}
              />
            );
          })}
      </div>
    );
  }

  if (props.table && props.graph) {
    const courses = props.courses.sortBy((course) => course.get("order"));
    const enabled = [];
    courses.map((course) => {
      if (course.get("started") === true) {
        return enabled.push(course.get("order").toString());
      }

      return false;
    });

    return (
      <div className={classNames("progress-in-row", props.table && "small")}>
        {props.courses
          .sortBy((course) => course.get("order"))
          .map((course, i) => {
            const graph =
              props.graph.find(
                (g) => g.get("course_id") === course.get("course_id")
              ) || Map();

            return (
              <ProgressBar
                table={props.table}
                active={Number(props.activeTab) === i + 1}
                // disabled={enabled.indexOf((i + 1).toString()) < 0}
                progress={graph.get("value") || 0}
                maximum={graph.get("maximum") || 0}
                number={course.get("order")}
                key={i}
                courses={props.courses.sortBy((course) => course.get("order"))}
              />
            );
          })}
      </div>
    );
  }

  if (props.graph) {
    return (
      <div className={classNames("progress-in-row", props.table && "small")}>
        {props.courses
          .sortBy((course) => course.get("order"))
          .map((course, i) => {
            const graph = props.graph.get(i) || {};

            return (
              <ProgressBar
                table={props.table}
                active={Number(props.activeTab) === i + 1}
                disabled={false}
                progress={graph.get("value") || 0}
                maximum={graph.get("maximum") || 0}
                number={course.get("order")}
                key={i}
                courses={props.courses.sortBy((course) => course.get("order"))}
              />
            );
          })}
      </div>
    );
  } else {
    const courses = props.user
      .get("courses")
      .sortBy((course) => course.get("order"));
    const graphs = props.graph || props.user.get("graph");
    const enabled = [];
    courses.map((course) => {
      if (course.get("started") === true) {
        return enabled.push(course.get("order").toString());
      }

      return false;
    });

    return (
      <div className={classNames("progress-in-row", props.table && "small")}>
        {courses.map((course, i) => {
          const graph =
            graphs.find(
              (graph) => graph.get("course_id") === course.get("course_id")
            ) || Map();

          return (
            <ProgressBar
              table={props.table}
              active={Number(props.activeTab) === i + 1}
              // disabled={enabled.indexOf((i + 1).toString()) < 0}
              progress={graph.get("value") || 0}
              maximum={graph.get("maximum") || 0}
              number={course.get("order")}
              key={i}
              courses={courses}
            />
          );
        })}
      </div>
    );
  }
};

ProgressBlock.propTypes = {
  activeTab: PropTypes.string,
  table: PropTypes.bool,
  user: PropTypes.object.isRequired,
  graph: PropTypes.any,
};

export default connect((state) => ({
  user:
    state.users.get("user").get("role") === "user"
      ? state.users.get("user")
      : state.users.getIn(["users", Number(state.router.route.vars.id)]), // eslint-disable-line
}))(ProgressBlock);
