import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function getUserData(user) {
    let name;
    let region;
    let office;

    if (user.get('name') && user.get('name') !== 'null' && user.get('name') !== 'undefined') {
        name = user.get('name');
    } else {
        name = user.get('username');
    }

    if (user.get('region') && user.get('region') !== 'null' && user.get('region') !== 'undefined') {
        region = user.get('region');
    } else {
        region = <FormattedMessage id="UNKNOWN" />;
    }

    if (user.get('office') && user.get('office') !== 'null' && user.get('office') !== 'undefined') {
        office = user.get('office');
    } else {
        office = <FormattedMessage id="UNKNOWN" />;
    }
    const username = user.get('username');

    return {
        name,
        region,
        office,
        username
    };
}
