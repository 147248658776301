// Vendor
import React, { PropTypes } from 'react';
import classNames from 'classnames';

/**
 * Container
 * @param props
 * @constructor
 */
const Container = (props) => ((
    <div className={classNames('container', props.relative && 'relative')}>
        {props.children}
    </div>
));

Container.propTypes = {
    children: PropTypes.any,
    relative: PropTypes.bool
};

export default Container;
