import React, { Component, PropTypes } from "react";
import ItemTypes from "./ItemTypes";
import classNames from "classnames";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { findDOMNode } from "react-dom";
import { DragSource, DropTarget } from "react-dnd";
import { FormattedMessage } from "react-intl";

import { EditButton } from "./../../../../components";
import { RowBreak } from "./../../../User/Main/components";
import EditCourseModal from "./EditCourseModal";
import { Button } from "../../../../components";
import { archiveCourse, getCourses } from "../../../../actions/user";
import ConfirmModal from "./ConfirmModal";

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};

const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.moveCard(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex; // eslint-disable-line
  },
};

@DropTarget(ItemTypes.CARD, cardTarget, (connect) => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource(ItemTypes.CARD, cardSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))
class Card extends Component {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    isDragging: PropTypes.bool.isRequired,
    id: PropTypes.any.isRequired,
    card: PropTypes.object.isRequired,
    moveCard: PropTypes.func.isRequired,
    archiveCourse: PropTypes.func.isRequired,
    getCourses: PropTypes.func.isRequired,
  };

  static contextTypes = {
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
  };

  showEditModal(id) {
    const { addModal } = this.context;

    addModal(<EditCourseModal courseId={id} />);
  }

  archiveCourse = (id) => {
    const { archiveCourse, getCourses } = this.props;
    const { removeModal } = this.context;

    archiveCourse(id).then((action) => {
      if (!action.error) {
        removeModal();
        getCourses();
      }
    });
  };

  render() {
    const { addModal } = this.context;
    const {
      archiveCourseLoading,
      card,
      isDragging,
      connectDragSource,
      connectDropTarget,
    } = this.props;
    const opacity = isDragging ? 0 : 1;

    return connectDragSource(
      connectDropTarget(
        <div
          style={{ opacity }}
          className={classNames("theme-row", `theme${card.get("order")}`)}
        >
          <div className="left">
            {card.get("active") && (
              <EditButton
                onClick={this.showEditModal.bind(this, card.get("id"))}
              />
            )}
          </div>

          <div className={classNames("top", !card.get("active") && "disabled")}>
            <span className="row-item">
              <span className="title">{card.get("name")}</span>
              <FormattedMessage id="THEME_TITLE" />
            </span>
            <span className="row-item">
              <span className="title">{card.get("adviser")}</span>
              <FormattedMessage id="LECTOR" />
            </span>
            <span className="row-item">
              <span className="title">{card.get("place")}</span>
              <FormattedMessage id="PLACE" />
            </span>
            <span className="row-item">
              <span className="title">
                {moment(card.get("start_date")).format("DD.MM.YYYY HH:mm")}
              </span>
              <FormattedMessage id="DATE" />
            </span>

            <RowBreak noPadding />

            <span className={classNames("row-item", "assignment")}>
              <span
                className={classNames("title", "task")}
                dangerouslySetInnerHTML={{ __html: card.get("task") }}
              />
              <FormattedMessage id="ASSIGNMENT" />
            </span>
          </div>

          <div
            className={classNames("right", !card.get("active") && "disabled")}
          >
            <FormattedMessage id={card.get("active") ? "ACTIVE" : "INACTIVE"} />
            {card.get("active") ? (
              <Button
                disabled={archiveCourseLoading}
                className="danger"
                style={{ maxWidth: 105, margin: "10px auto" }}
                onClick={() =>
                  addModal(
                    <ConfirmModal
                      course={card}
                      onSubmit={() => this.archiveCourse(card.get("id"))}
                    />
                  )
                }
              >
                <FormattedMessage id="ARCHIVE" />
              </Button>
            ) : null}
          </div>
        </div>
      )
    );
  }
}

export default connect(
  (state) => ({
    archiveCourseLoading: state.users.get("archiveCourseLoading"),
  }),
  (dispatch) => bindActionCreators({ archiveCourse, getCourses }, dispatch)
)(Card);
