export const validate = values => {
    const errors = {};

    if (!values.text) {
        errors.text = 'REQUIRED';
    }

    if (!values.answers || values.answers.length < 3) {
        errors.answers = 'REQUIRED';
    }

    return errors;
};
