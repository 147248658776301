import request from 'superagent-bluebird-promise';
import {
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILURE
} from '../../constants/actionTypes';

/**
 * Fetch user action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function fetchUser() {
    return {
        types: [FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_FAILURE],
        promise: request
                    .get(`${process.env.REACT_APP_API_URL}/api/v1/user?call=${Date.now()}`)
                    .set('Authorization', `Bearer ${sessionStorage.getItem('access_token')}`)
    };
}
