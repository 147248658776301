import request from 'superagent-bluebird-promise';
import {
    FETCH_COURSE_REQUEST,
    FETCH_COURSE_SUCCESS,
    FETCH_COURSE_FAILURE
} from '../../constants/actionTypes';

/**
 * Fetch course action
 *
 * @param{Number} userId
 * @param{Number} id
 * @returns {{types: *[], promise: Request }}
 */

export default function fetchCourse(userId, id) {
    return {
        types: [FETCH_COURSE_REQUEST, FETCH_COURSE_SUCCESS, FETCH_COURSE_FAILURE],
        promise: request
            .get(`${process.env.REACT_APP_API_URL}/api/v1/user/${userId}/course/${id}?call=${Date.now()}`)
            .set('Authorization', `Bearer ${sessionStorage.getItem('access_token')}`),
        id
    };
}
