import React, { Component, PropTypes } from 'react';
import DropZone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Modal, Button } from './../../../../components';

import { uploadFiles } from './../../../../actions/user';
import { checkSupport } from './../../../../helpers';

const fields = [
    { index: 0, key: 'organization_struct' },
    { index: 1, key: 'participants' },
    { index: 2, key: 'managers' }
];

class UploadModal extends Component {
    constructor(...args) {
        super(...args);
        this.state = {
            files: [],
            isLoading: false,
            errors: []
        };
    }

    onDrop = (uploadedFiles, index) => {
        const { files } = this.state;
        files[index] = uploadedFiles[0];
        this.setState({ files });
    };

    handleSubmit = () => {
        const { files } = this.state;
        let { errors } = this.state;
        const { uploadFiles } = this.props;
        const { addModal, removeModal } = this.context;
        errors = [];
        files.map((file, index) => {
            if (file.type.indexOf('csv') < 0 && checkSupport()) {
                errors[index] = true;
            }

            return this.setState({ errors });
        });

        if (errors.length === 0) {
            this.setState({ isLoading: true });
            uploadFiles(files).then(
                (action) => {
                    if (!action.error) {
                        removeModal();
                        addModal(<Modal heading="SUCCESSFULLY_UPLOADED" onClose={removeModal} />);
                    } else {
                        removeModal();
                        addModal(<Modal heading="ERROR_OCCURRED" onClose={removeModal} />);
                    }
                    this.setState({ isLoading: false });
                }
            );
        }
    };

    render() {
        const { removeModal } = this.context;
        const { files, errors, isLoading } = this.state;

        return (
            <Modal
                heading="UPLOAD_FILES"
                onClose={removeModal}
            >
                <div style={{ marginTop: 40, textAlign: 'center' }}>
                    {fields.map((field) => (
                        <div>
                            <DropZone
                                onDrop={(e) => this.onDrop(e, field.index)}
                                className="upload-modal-drop-zone"
                                multiple={false}
                            >
                                {files[field.index]
                                    ? files[field.index].name
                                    : <FormattedMessage id={field.key} />
                                }
                            </DropZone>
                            {errors[field.index] && <div className="error">Nepodporovaný typ súboru</div>}
                        </div>
                    ))}

                    <p>Pre nahratie súborov, kliknite (alebo pretiahnite súbor) na vyznačené plochy.</p>

                    <Button
                        disabled={!files[0] || !files[1] || !files[2]}
                        onClick={this.handleSubmit}
                    >{isLoading ? 'Nahrávam' : 'Nahraj'}</Button>
                </div>
            </Modal>
        );
    }
}

UploadModal.propTypes = {
    uploadFiles: PropTypes.func.isRequired
};

UploadModal.contextTypes = {
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired
};

export default connect(
    undefined,
    dispatch => (
        bindActionCreators({ uploadFiles }, dispatch)
    )
)(UploadModal);
