import React, { PropTypes, Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

import { EditButton } from "./../../../../../components";
import EditModal from "./EditModal";

import { getUserData, isRba } from "./../../../../../helpers";
import { isBba } from "../../../../../helpers";

class UserInfoTable extends Component {
  showEditModal() {
    const { addModal, removeModal } = this.context;

    addModal(<EditModal onClose={removeModal} />);
  }

  render() {
    const { user, editMode } = this.props;
    let value = 0;
    let max = 0;

    if (!user) {
      return false;
    }

    if (user && user.get("graph") && user.get("graph").size > 0) {
      user.get("graph").map((item) => {
        value +=
          isBba() || isRba()
            ? Math.min(100, item.get("value"))
            : item.get("value");
        max +=
          isBba() || isRba()
            ? Math.min(100, item.get("maximum"))
            : item.get("maximum");

        return true;
      });
    }

    return (
      <div className="info-table">
        {isRba() && <div className="white-background" />}
        <div className="info-table__left">
          {user.get("image") ? (
            <span
              className="photo"
              style={{
                background: `url(${process.env.REACT_APP_API_URL}${user.get(
                  "image"
                )}) center center / cover no-repeat`,
              }}
            />
          ) : (
            <span className="photo-placeholder" />
          )}

          <table>
            <tbody>
              <tr>
                <td>{getUserData(user).name}</td>
                <td>
                  <FormattedMessage id="NAME" />
                </td>
              </tr>
              <tr>
                <td>{getUserData(user).office}</td>
                <td>
                  <FormattedMessage id="OFFICE" />
                </td>
              </tr>
              <tr>
                <td>{getUserData(user).region}</td>
                <td>
                  <FormattedMessage id="REGION" />
                </td>
              </tr>
            </tbody>
            {editMode && (
              <EditButton
                onClick={this.showEditModal.bind(this)}
                className="edit-button"
              />
            )}
          </table>
        </div>

        <div className="info-table__progress-info">
          <span className="large">
            <FormattedMessage id="TOTAL_SCORE" />
          </span>
          <span className="main">{value} b</span>
          <span className="small">
            z {isRba() ? Math.min(500, max) : max} b
          </span>
        </div>
      </div>
    );
  }
}

UserInfoTable.contextTypes = {
  addModal: PropTypes.func.isRequired,
  removeModal: PropTypes.func.isRequired,
};

UserInfoTable.propTypes = {
  editMode: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  loggedUser: PropTypes.object.isRequired,
};

export default connect((state) => ({
  editMode: state.users.get("user").get("role") === "user",
  loggedUser: state.users.get("user"),
  user:
    state.users.get("user").get("role") === "user"
      ? state.users.get("user")
      : state.users.getIn(["users", Number(state.router.route.vars.id)]), // eslint-disable-line
}))(UserInfoTable);
