// Vendor
import React, { Component, PropTypes } from 'react';
import Card from './Card';
import HTML5Backend from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-dnd';
import { FormattedMessage } from 'react-intl';

// Components
import RowBreak from './../../../User/Main/components/RowBreak';

@DragDropContext(HTML5Backend)
class ThemesTable extends Component {
    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
        this.moveCard = this.moveCard.bind(this);
    }

    /**
     * Move card
     * @param dragIndex
     * @param hoverIndex
     */
    moveCard() {
       /*
       ** do card
        */
    }

    /**
     * Render
     * @returns {XML}
     */
    render() {
        const { courses } = this.props;

        return (
            <div>
                {courses.filter((course) => course.get('active')).map((course, i) => (
                    <Card
                        key={course.get('id')}
                        index={i}
                        id={course.get('id')}
                        card={course}
                        moveCard={this.moveCard}
                    />
                ))}
                <RowBreak>
                    <FormattedMessage id="ARCHIVED" />
                </RowBreak>
                {courses.filter((course) => !course.get('active')).map((course, i) => (
                    <Card
                        key={course.get('id')}
                        index={i}
                        id={course.get('id')}
                        card={course}
                        moveCard={this.moveCard}
                    />
                ))}
            </div>
        );
    }
}

// Prop types
ThemesTable.propTypes = {
    courses: PropTypes.func.isRequired
};

export default connect(
    state => ({
        courses: state.users.get('adminCourses').toArray()
    })
)(ThemesTable);
