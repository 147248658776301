import request from 'superagent-bluebird-promise';
import {
    EDIT_COURSE_REQUEST,
    EDIT_COURSE_SUCCESS,
    EDIT_COURSE_FAILURE
} from '../../constants/actionTypes';

/**
 * Edit course action
 *
 * @param{Number} courseId
 * @param{Object} data
 * @returns {{types: *[], promise: Request }}
 */

export default function editCourse(courseId, data) {
    return {
        types: [EDIT_COURSE_REQUEST, EDIT_COURSE_SUCCESS, EDIT_COURSE_FAILURE],
        promise: request
                    .put(`${process.env.REACT_APP_API_URL}/api/v1/course/${courseId}?call=${Date.now()}`)
                    .set('Authorization', `Bearer ${sessionStorage.getItem('access_token')}`)
                    .send({
                        name: data.theme_name,
                        adviser: data.adviser,
                        place: data.place,
                        start_date: data.start_date,
                        task: data.task
                    }),
        courseId
    };
}
