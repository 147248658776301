import React, { Component, PropTypes } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

export default class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  render() {
    const { field, placeholder, type, min, max, error } = this.props;
    const { active } = this.state;

    field.onFocus = () => {
      this.setState({ active: true });
    };
    field.onBlur = () => {
      this.setState({ active: false });
    };

    return (
      <div
        className={classNames(
          "input-component",
          active && "active",
          error && "error"
        )}
        onClick={() => {
          this.input.focus();
        }}
      >
        <span
          className={classNames(
            "input-component__placeholder",
            active && "active"
          )}
        >
          <FormattedMessage id={`FIELDS.${placeholder}`} />
        </span>

        <input
          className={classNames(active && "active")}
          type={type}
          autoComplete="off"
          ref={(input) => (this.input = input)}
          {...{ ...field, min, max }}
        />
      </div>
    );
  }
}

Input.propTypes = {
  field: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  error: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
};
