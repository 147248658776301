// Vendor
import React, { Component, PropTypes } from 'react';
import { validate } from './validate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field, change } from 'redux-form';

// Components
import { Button } from './../../../components';

// Actions
import { renderInput } from './../../../forms/actions';

// Const
const form = 'editNotice';

/**
 * Edit notice form
 * @class
 */
class EditNoticeForm extends Component {
    componentDidMount() {
        const { change, courseId, courses } = this.props;
        const notice = courses.get(courseId).get('notice');

        if (notice) {
            change(form, 'value', notice);
        }
    }

    render() {
        const { handleSubmit, isLoading } = this.props;

        return (
            <form autoComplete="off" onSubmit={handleSubmit}>
                <div style={{ margin: '3em 0' }}>
                    <Field name={'value'} component={renderInput} type="textarea" />
                </div>

                <div style={{ textAlign: 'center' }}>
                    <Button
                        type="button"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        <FormattedMessage id={isLoading ? 'LOADING' : 'SAVE'} />
                    </Button>
                </div>
            </form>
        );
    }
}

// Prop types
EditNoticeForm.propTypes = {
    isLoading: PropTypes.bool,
    change: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    courseId: PropTypes.number,
    courses: PropTypes.object
};

// Connect
EditNoticeForm = connect(
    state => ({
        isLoading: state.users.get('editNoticeLoading'),
        courses: state.users.get('courses')
    }),
    dispatch => (
        bindActionCreators({ change }, dispatch)
    )
)(EditNoticeForm);


export default reduxForm({
    form,
    validate
})(EditNoticeForm);

