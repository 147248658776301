import React from "react";
import { FormattedMessage } from "react-intl";

import { Input, TextArea } from "./../components";

const renderInput = (field) => (
  <div>
    {field.type === "textarea" ? (
      <TextArea
        field={field.input}
        type={field.type}
        placeholder={field.input.name}
        error={(field.meta.touched && field.meta.error) || field.fieldError}
      />
    ) : (
      <Input
        autoComplete="off"
        field={field.input}
        type={field.type}
        placeholder={field.input.name}
        error={(field.meta.touched && field.meta.error) || field.fieldError}
        min={field.min}
        max={field.max}
      />
    )}
    {field.visibleError && field.meta.touched && field.meta.error && (
      <span>
        <FormattedMessage id={`ERRORS.${field.meta.error}`} />
      </span>
    )}
  </div>
);

export default renderInput;
