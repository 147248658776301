import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { actions } from '../../routex';
import { reducer as formReducer } from 'redux-form';
import * as reducers from './reducers';
import { promiseMiddleware, actionInterceptorMiddleware } from './middlewares';

import {
    LOGIN_SUCCESS,
    LOGOUT_REQUEST,
    FETCH_USER_SUCCESS,
    UPLOAD_FILES_SUCCESS
} from './constants/actionTypes';

export default function create(initialState, routex) {
    const routexReducer = routex.reducer;
    let dispatch; // eslint-disable-line

    const redirectOnSetStorage = () => {
        const interval = setInterval(() => {
            if (sessionStorage.getItem('access_token')) {
                dispatch(actions.transitionTo('/'));
                clearInterval(interval);
            }
        }, 10);
    };

    const interceptorMiddleware = actionInterceptorMiddleware({
        [LOGIN_SUCCESS]: (action, next) => {
            sessionStorage.setItem('access_token', action.result.body.access_token);
            redirectOnSetStorage();

            return next(action);
        },
        [FETCH_USER_SUCCESS]: (action, next) => {
            const user = action.result.body;
            if (user.role === 'lector' || user.role === 'superior') {
                if (window.location.href.indexOf('admin') < 0) {
                    dispatch(actions.transitionTo('/admin/participants'));
                }
            }

            return next(action);
        },
        [LOGOUT_REQUEST]: (action, next) => {
            sessionStorage.removeItem('access_token');
            dispatch(actions.transitionTo('/auth/login'));

            return next(action);
        },
        [UPLOAD_FILES_SUCCESS]: (action, next) => {
            setTimeout(() => {
                window.location = '/admin/participants';
            }, 2000);

            return next(action);
        }
    });

    const checkTokenMiddleware = () => (
        (next) => (action) => {
            if (action.error) {
                if (action.error.status === 401) {
                    dispatch(actions.transitionTo('/auth/login'));
                    return next(action);
                }
            }

            return next(action);
        }
    );

    const store = compose(
        routex.store,
        applyMiddleware(
            checkTokenMiddleware,
            promiseMiddleware,
            interceptorMiddleware
        )
    )(createStore)(
        combineReducers({ ...reducers, ...routexReducer, form: formReducer }),
        initialState
    );

    dispatch = store.dispatch; // eslint-disable-line

    return store;
}
