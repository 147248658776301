import request from 'superagent-bluebird-promise';
import {
    EVALUATE_QUIZ_REQUEST,
    EVALUATE_QUIZ_SUCCESS,
    EVALUATE_QUIZ_FAILURE
} from '../../constants/actionTypes';

/**
 * Evaluate quiz action
 *
 * @param{String} id
 * @param{Object} answers
 * @returns {{types: *[], promise: Request }}
 */

export default function evaluateQuiz(id, answers) {
    const data = [];
    Object.keys(answers).map((key) => (
        data.push({
            question_id: Number(key),
            answer_id: Number(answers[key])
        })
    ));

    return {
        types: [EVALUATE_QUIZ_REQUEST, EVALUATE_QUIZ_SUCCESS, EVALUATE_QUIZ_FAILURE],
        promise: request
                    .put(`${process.env.REACT_APP_API_URL}/api/v1/user/course/${id}/quiz`)
                    .set('Authorization', `Bearer ${sessionStorage.getItem('access_token')}`)
                    .send(data),
        id
    };
}
