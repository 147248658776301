exports.__esModule = true;

const _extends =
  Object.assign ||
  function (target) {
    for (let i = 1; i < arguments.length; i++) {
      const source = arguments[i];
      for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

const _invariant = require('invariant');

const _invariant2 = _interopRequireDefault(_invariant);

const _utilsRouteUtils = require('./utils/routeUtils');

const _utilsRouterUtils = require('./utils/routerUtils');

const _utilsStringUtils = require('./utils/stringUtils');

const _errors = require('./errors');

const _utilsUrlUtils = require('./utils/urlUtils');

/**
 * Resolves async routes and returns Promise which resolves to normalized definitions
 *
 * @param {Function} children
 * @returns {Promise}
 */
function resolveAsyncRoutes(children) {
  return new Promise(function (resolve, reject) {
    const routes = children();

    if (!(routes instanceof Promise)) {
      const type = typeof routes;

      reject(
        Error(
          `Async route definition resolvers should return a promise, ${type} given.`
        )
      );
    }

    routes.then(function (_routes) {
      if (!Array.isArray(_routes)) {
        const type = typeof _routes;

        reject(
          Error(
            `Async route definition resolvers should resolve to array, ${type} given.`
          )
        );
      }

      resolve(_routes);
    }, reject);
  });
}

/**
 * Resolves child routes (sync and async too)
 *
 * @param {Array|Function} children
 * @returns {Promise}
 */
function resolveChildRoutes(children) {
  function normalizeRoutes(routes, onError) {
    try {
      return routes.map(function (route) {
        return _utilsRouteUtils.normalizeRouteDefinition(route);
      });
    } catch (e) {
      onError(e);
    }
  }

  return new Promise(function (resolve, reject) {
    if (!Array.isArray(children)) {
      resolveAsyncRoutes(children).then(function (routes) {
        return resolve(normalizeRoutes(routes, reject));
      }, reject);
    } else if (!children.length) {
      resolve([]);
    } else {
      resolve(normalizeRoutes(children, reject));
    }
  });
}

const Route = (function () {
  function Route(path, basePath, children, onEnter, onLeave, component) {
    if (path === undefined) path = '/';
    if (basePath === undefined) basePath = '/';
    if (children === undefined) children = [];
    const attrs =
      arguments.length <= 6 || arguments[6] === undefined ? {} : arguments[6];

    _classCallCheck(this, Route);

    const pathType = typeof path;
    const basePathType = typeof basePath;
    const childrenType = typeof children;
    const onEnterType = typeof onEnter;
    const onLeaveType = typeof onLeave;

    _invariant2.default(
      pathType === 'string',
      `Route path should be string, ${pathType} given.`
    );
    _invariant2.default(
      basePathType === 'string',
      `Route base path should be string, ${basePathType} given.`
    );
    _invariant2.default(
      Array.isArray(children) || childrenType === 'function',
      `Route children should be an array or function, ${childrenType} given.`
    );
    _invariant2.default(
      onEnterType === 'function',
      `Route handler \`onEnter\` should be a function, ${onEnterType} given.`
    );
    _invariant2.default(
      onLeaveType === 'function',
      `Route handler \`onLeave\` should be a function, ${onLeaveType} given.`
    );

    /**
     * Eager matcher for this route only
     *
     * @type {null|Function}
     */
    this.matcher = null;

    /**
     * Non eager matcher for this route (will match this route + something more)
     *
     * @type {null|Function}
     */
    this.childMatcher = null;

    this.path = path;

    this.basePath = basePath;

    this.onEnter = onEnter;

    this.onLeave = onLeave;

    this.component = component;

    this.children = children;

    this.attrs = attrs;
  }

  Route.prototype.match = function match(path, query) {
    const _this = this;

    return new Promise(function (resolve, reject) {
      // lazy create matchers
      if (_this.matcher === null) {
        const _buildMatcher = _utilsRouteUtils.buildMatcher(
          _this.path,
          _this.basePath
        );

        const { eager } = _buildMatcher;
        const { nonEager } = _buildMatcher;

        _this.matcher = eager;
        _this.childMatcher = nonEager;
      }

      const instantiateRoutes = function instantiateRoutes(routes) {
        return routes.map(function (route) {
          return new Route(
            route.path,
            _utilsStringUtils.normalizeSlashes(
              `${_this.basePath}/${_this.path}`
            ),
            route.children,
            route.onEnter,
            route.onLeave,
            route.component,
            route.attrs
          );
        });
      };

      // this resolves current path using eager regexp
      // in case children does not match
      const resolveOnlyCurrentRoute = function resolveOnlyCurrentRoute() {
        const match = _this.matcher(path);

        if (match) {
          const { vars } = match;

          return resolve({
            pathname: path,
            vars,
            query,
            fullPath: _utilsUrlUtils.createHref(path, query),
            components: [_this.component],
            onEnter: [_this.onEnter],
            onLeave: [_this.onLeave],
            attrs: _this.attrs
          });
        }

        return reject();
      };

      // this resolves current route only if child routes returned
      // NoRoutesToResolveError ( means children is empty )
      const resolveOnlyCurrentIfNoError = function resolveOnlyCurrentIfNoError(
        err
      ) {
        if (!err || err instanceof _errors.NoRoutesToResolveError) {
          resolveOnlyCurrentRoute();
        } else {
          reject(err);
        }
      };

      // if child matchers matches, try to match children first
      const childMatch = _this.childMatcher(path);

      if (childMatch) {
        // resolve children routes
        resolveChildRoutes(_this.children).then(function (routes) {
          try {
            _this.children = instantiateRoutes(routes);

            // try to match children and resolve with first matched
            _utilsRouterUtils
              .resolveWithFirstMatched(_this.children, path, query)
              .then(
                function (match) {
                  const { vars } = match;
                  const { onEnter } = match;
                  const { onLeave } = match;
                  const { components } = match;
                  const { attrs } = match;

                  resolve({
                    pathname: path,
                    vars,
                    query,
                    fullPath: _utilsUrlUtils.createHref(path, query),
                    components: [_this.component].concat(components),
                    onEnter: [_this.onEnter].concat(onEnter),
                    onLeave: [_this.onLeave].concat(onLeave),
                    attrs: _extends({}, _this.attrs, attrs)
                  });
                },
                resolveOnlyCurrentIfNoError // this is called when children don't match
              );
          } catch (e) {
            reject(e);
          }
        }, reject);
      } else {
        resolveOnlyCurrentRoute();
      }
    });
  };

  return Route;
})();

exports.default = Route;
module.exports = exports.default;
