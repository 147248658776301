import React, { PropTypes } from 'react';
import classNames from 'classnames';

const RowBreak = (props) => ((
    <div
        className={
            classNames(
                'row-break',
                props.noPadding && "no-padding",
                props.noPaddingTop && "no-padding-top"
            )
        }
    >
        <span className="row-break__line" />
        <span className={classNames('row-break__text', props.black && 'black')}>
            {props.children}
        </span>
    </div>
));

RowBreak.propTypes = {
    children: PropTypes.any,
    black: PropTypes.bool,
    noPadding: PropTypes.bool,
    noPaddingTop: PropTypes.bool
};

export default RowBreak;
