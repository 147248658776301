// Vendor
import React from 'react';

// Components
import { AdminTable, QuizThemesTable } from './../components';

/**
 * Quiz
 * @class
 */
const Quiz = () => ((
    <AdminTable
        heading="THEME_LIST"
        buttonGroup={<span />}
    >
        <QuizThemesTable />
    </AdminTable>
));

export default Quiz;
