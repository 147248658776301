// Vendor
import React, { Component, PropTypes } from 'react';
import DropZone from 'react-dropzone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field, change } from 'redux-form';
import fixOrientation from 'fix-orientation';

// Components
import { Button } from './../../../components';

// Actions
import { renderInput } from './../../../forms/actions';
import { normalizeError, dataUriToBlob } from './../../../helpers';
import { validate } from './validate';

// Const
const form = 'editUser';
const fields = ['image', 'name', 'office', 'region'];

/**
 * Edit user form
 * @class
 */
class EditUser extends Component {
    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            preview: null
        };
    }

    /**
     * Component did mount
     */
    componentDidMount() {
        const { change, user } = this.props;

        fields.map((field) => (
            change(form, field, user.get(field))
        ));

        change(form, 'intro_passed', user.get('intro_passed'));
    }

    /**
     * On drop
     * @param files
     */
    onDrop(files) {
        const { change } = this.props;

        const fr = new FileReader();
        fr.onload = (e) => {
            fixOrientation(e.target.result, { image: true }, (fixed) => {
                change(form, 'image', dataUriToBlob(fixed));
                this.setState({ preview: fixed });
            });
        };
        fr.readAsDataURL(files[0]);
    }

    /**
     * Remove photo
     */
    removePhoto() {
        const { change } = this.props;
        change(form, 'image', null);
        this.setState({ preview: null });
    }

    /**
     * Render
     * @returns {XML}
     */
    render() {
        const { handleSubmit, user, isLoading, editError } = this.props;
        const { preview } = this.state;
        const introPassed = user.get('intro_passed');
        let background = null;

        if (preview) {
            background = preview;
        } else {
            if (user && user.get('image')) {
                background = `${process.env.REACT_APP_API_URL}${user.get('image')}`;
            }
        }

        return (
            <form autoComplete="off" onSubmit={handleSubmit}>
                {user.get('role') === 'user' &&
                    <span style={{ position: 'relative', display: 'inline-block' }}>
                        <DropZone
                            onDrop={this.onDrop.bind(this)}
                            className="drop-zone"
                            style={{ background: `url(${background}) center center / cover no-repeat` }}
                        />
                        {preview &&
                            <button className="remove-photo-button" onClick={this.removePhoto.bind(this)} />
                        }
                    </span>
                }

                <div style={{ margin: '3em 0' }}>
                    {user.get('role') === 'user' &&
                        <div>
                            <Field name={'name'} visibleError component={renderInput} type="text" />
                            <Field name={'office'} visibleError component={renderInput} type="text" />
                            <Field name={'region'} visibleError component={renderInput} type="text" />
                        </div>
                    }
                    {!introPassed &&
                        <div>

                            <Field name={'intro_passed'} component={'input'} type="hidden" />
                            <Field name={'password'} component={renderInput} type="password" visibleError />
                            <Field name={'password_again'} component={renderInput} type="password" visibleError />
                        </div>
                    }
                </div>

                {editError &&
                    <span className="error">
                        <FormattedMessage id={`ERRORS.${normalizeError(editError.get('error_code'))}`} />
                    </span>
                }

                <div style={{ textAlign: 'right' }}>
                    <Button
                        type="button"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        <FormattedMessage id={isLoading ? 'LOADING' : 'SAVE'} />
                    </Button>
                </div>
            </form>
        );
    }
}

// Prop types
EditUser.propTypes = {
    user: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    editError: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
};

// Connect
EditUser = connect(
    state => ({
        user: state.users.get('user'),
        isLoading: state.users.get('editUserLoading'),
        editError: state.users.get('editError')
    }),
    dispatch => (
        bindActionCreators({ change }, dispatch)
    )
)(EditUser);

export default reduxForm({
    form,
    validate
})(EditUser);

