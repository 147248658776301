// Vendor
import React, { PropTypes } from 'react';

// Components
import { UserInfoTable, MainTable } from './../../../../User/Main/components';

const Detail = () => ((
    <div>
        <UserInfoTable />
        <MainTable />
    </div>
));

Detail.propTypes = {
    user: PropTypes.object.isRequired,
    transitionTo: PropTypes.func.isRequired
};

export default Detail;
