import React, { Component, PropTypes } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field, change, touch } from 'redux-form';

import { Button } from './../../../components';

import { renderBasicInput } from './../../../forms/actions';

const form = 'questionEditForm';

@reduxForm({
    form
})
@connect(
    state => ({
        isLoading: state.users.get('editQuestionLoading') || state.users.get('createQuestionLoading')
    }),
    dispatch => (
        bindActionCreators({ change, touch }, dispatch)
    )
)
class QuestionForm extends Component {
    static propTypes = {
        isLoading: PropTypes.bool,
        change: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        edit: PropTypes.object,
        modal: PropTypes.bool,
        touch: PropTypes.func.isRequired
    };

    componentDidMount() {
        const { change, touch, edit } = this.props;

        if (edit) {
            change(form, 'text', edit.get('text'));
            touch(form, 'text');
            edit.get('answers').map((a, i) => {
                change(form, `answers[${i}]`, a.get('text'));
                touch(form, `answers[${i}]`);
                return true;
            });
        }
    }

    render() {
        const { handleSubmit, isLoading, modal, edit } = this.props;
        let answers = [];
        if (edit) {
            answers = edit.has('answers') ? edit.get('answers').toArray() : [];
        }

        return (
            <form autoComplete="off" onSubmit={handleSubmit}>
                <div className={classNames('questions-form', modal && 'in-modal')}>
                    <div style={{ marginBottom: '20px' }}>
                        <h5 style={{ marginTop: '0' }}><FormattedMessage id="QUESTION" /></h5>
                        <Field
                            name="text"
                            component={renderBasicInput} type="text"
                            placeholder="QUESTION_PLACEHOLDER"
                            visibleError
                        />
                    </div>

                    <div className="questions-form-left">
                        <h5><FormattedMessage id="ANSWERS" /></h5>
                        <Field
                            name="answers[0]"
                            blue={!edit || (edit && answers[0].get('is_correct'))}
                            component={renderBasicInput}
                            type="text"
                            placeholder={
                                (!edit || (edit && answers[0].get('is_correct')))
                                ? 'CORRECT_ANSWER'
                                : 'INCORRECT_ANSWER'
                            }
                            noError
                        />
                        <Field
                            name="answers[1]"
                            blue={edit && answers[1].get('is_correct')}
                            component={renderBasicInput}
                            type="text"
                            placeholder={edit && answers[1].get('is_correct') ? 'CORRECT_ANSWER' : 'INCORRECT_ANSWER'}
                            noError
                        />
                        <Field
                            name="answers[2]"
                            blue={edit && answers[2].get('is_correct')}
                            component={renderBasicInput}
                            type="text"
                            placeholder={edit && answers[2].get('is_correct') ? 'CORRECT_ANSWER' : 'INCORRECT_ANSWER'}
                            visibleError
                        />
                    </div>
                    <div className="questions-form-right">
                        <Button
                            type="button"
                            onClick={handleSubmit}
                            disabled={isLoading}
                        >
                            <img style={{ position: 'relative', top: '-2px' }} src={`/${process.env.REACT_APP_TYPE}/assets/icons/success.png`} alt="add" />
                            <FormattedMessage id={isLoading ? 'LOADING' : 'SAVE_QUESTION'} />
                        </Button>
                    </div>
                </div>
            </form>
        );
    }
}

export default QuestionForm
