import React, { Component, PropTypes } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

class AdminTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeFilter: false
        };
    }

    resetFilter() {
        const { search } = this.props;
        this.input.value = '';
        search('');
    }

    render() {
        const { children, heading, buttonGroup, intl, search, role, rightSearch } = this.props;
        const { activeFilter } = this.state;

        return (
            <div className="admin-table">
                <div className="admin-table__heading">
                    <span className="heading">
                        <FormattedMessage id={heading} />
                    </span>
                    {search &&
                        <span
                            className={classNames(
                                'input', (role === 'superior' || rightSearch) && 'superior'
                            )}
                        >
                            <input
                                type="text"
                                placeholder={intl.formatMessage({ id: 'SEARCH' })}
                                onChange={(e) => { search(e.target.value); }}
                                onFocus={() => this.setState({ activeFilter: true })}
                                onBlur={() => {
                                    setTimeout(() => { this.setState({ activeFilter: false }); }, 100);
                                }}
                                ref={(i) => (this.input = i)}
                            />
                            <span className="icon">
                                <button
                                    className={classNames(activeFilter && 'active')}
                                    onClick={
                                        !activeFilter
                                            ? () => { this.input.focus(); }
                                            : this.resetFilter.bind(this)
                                    }
                                />
                            </span>
                        </span>
                    }
                    {buttonGroup}
                </div>
                <div className="admin-table__body">
                    {children}
                </div>
            </div>
        );
    }
}

AdminTable.propTypes = {
    intl: PropTypes.object.isRequired,
    children: PropTypes.any,
    buttonGroup: PropTypes.any,
    heading: PropTypes.string,
    search: PropTypes.func,
    role: PropTypes.string,
    rightSearch: PropTypes.bool
};

AdminTable = connect(
    state => ({
        role: state.users.get('user').get('role')
    })
)(AdminTable);

export default injectIntl(AdminTable);
