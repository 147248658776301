// Utilities.
import { offset } from './';

/**
 * We need to monitor if user is scrolling in order to stop the animation.
 */
// Scrolling status.
let scrolling = false;

// Scrolling counter.
// We need to identify different interactions to disable previous scrollings.
let counter = 0;

// Watch for scrolling.
window.addEventListener(
    'mousewheel',
    () => {
        scrolling = true;
    }
);

/**
 * Scroll to an element.
 */
export default (element, duration, position = 'center', context = window) => {
    // We need an ID for the scrolling.
    const id = ++counter;

    // Reset scrolling status.
    scrolling = false;

    // Starting position.
    const initialY = context.scrollY;

    // Destination.
    let marginY;

    // The element is set.
    if (element) {
        // Bounding box.
        const box = element.getBoundingClientRect();

        // Get the element offset.
        // I would have used just the `getBoundingClientRect()`, but it takes `style.transform` into account.
        const elementOffset = offset(element);

        // Destination.
        marginY = elementOffset.top - context.scrollY;

        // Center the view?
        if (position === 'center') {
            marginY -= (context.innerHeight + box.height / 2) / 2;
            // Use exact number?
        } else {
            marginY -= parseInt(position, 10);
        }
        // Top of the page.
    } else {
        // Destination.
        marginY = - context.scrollY - parseInt(position, 10);
    }

    // Current frame.
    let frame = 0;

    // Number of frames of the animation.
    const totalFrames = duration / 60; // 60 FPS!

    /**
     * Animation step.
     */
    const step = () => {
        // We only scroll using the last interaction (`id === counter`).
        // If the user is scrolling, we want to stop the animation.
        // The position is still not right.
        if (id === counter && !scrolling && frame < totalFrames) {
            // Scroll towards the position.
            // Some sick mathz, yo! (I'm tired, so I googled it, despite the fact it's basic high school math…).
            window.scrollTo(0, - marginY / 2 * (Math.cos(Math.PI * ++frame / totalFrames) - 1) + initialY);

            // Next frame.
            requestAnimationFrame(step);
        }
    };

    // Start animating.
    requestAnimationFrame(step);
};
