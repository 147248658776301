import React, { Component, PropTypes } from "react";
import { FormattedMessage } from "react-intl";

import { Button, Modal } from "./../../../../../components";

export default class ConfirmModal extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  static contextTypes = {
    removeModal: PropTypes.func.isRequired,
  };

  render() {
    const { user, onSubmit } = this.props;
    const { removeModal } = this.context;

    const isLoading = false;

    return (
      <Modal onClose={removeModal}>
        <p>
          {`Naozaj chcete resetnúť kvíz pre používateľa ${user.get("name")}?`}
        </p>
        <div style={{ textAlign: "center", margin: "3em 0 1em 0" }}>
          <Button
            disabled={isLoading}
            onClick={removeModal}
            style={{ marginRight: 20 }}
          >
            <FormattedMessage id="CANCEL" />
          </Button>
          <Button className="danger" disabled={isLoading} onClick={onSubmit}>
            <FormattedMessage id={isLoading ? "LOADING" : "RESET"} />
          </Button>
        </div>
      </Modal>
    );
  }
}
