/**
 * Calculate the offset using standard method.
 * @param {Element} element Checked element.
 * @returns {Object} Offsets (left and top).
 */
export default (element) => {
    // Currently checked element.
    let currentElement = element;

    // Offsets initialization.
    let left = 0;
    let top = 0;

    do {
        // Add the offsets.
        left += currentElement.offsetLeft; // do not use scrollLeft, or scrollTop, because it won't work in firefox
        top += currentElement.offsetTop;

        // Get the next ancestor.
        currentElement = currentElement.offsetParent;
    } while (currentElement);

    // Return the offset.
    return {
        left,
        top
    };
};
