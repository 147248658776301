exports.__esModule = true;

const _extends =
  Object.assign ||
  function (target) {
    for (let i = 1; i < arguments.length; i++) {
      const source = arguments[i];
      for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

exports.default = createRoutex;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

const _Router = require('./Router');

const _Router2 = _interopRequireDefault(_Router);

const _actionTypes = require('./actionTypes');

const _actions = require('./actions');

/**
 * Creates routex instance and returns store, reducer and router insance
 *
 * @param {Array} routes
 * @param {Object} history
 * @param {?Function} onTransition
 * @returns {{router: Router, store: store, reducer: {router: reducer}}}
 */

function createRoutex(routes, history, onTransition) {
  const initialReducerState = { state: 'INITIAL', route: null };

  const router = new _Router2.default(routes, history, onTransition);

  const store = function store(next) {
    return function (reducer, initialState) {
      const modifiedInitialState = initialState;

      // reset state of reducer to be initial because components cannot be rehydrated immediately
      // because we need to wait for initial router.run
      if (
        typeof initialState === 'object' &&
        initialState !== null &&
        initialState.hasOwnProperty('router')
      ) {
        modifiedInitialState.router = initialReducerState;
      }

      const nextStore = next(reducer, modifiedInitialState);

      /**
       * Dispatch function of this store
       *
       * @param {*} action
       * @returns {*}
       */
      function dispatch(action) {
        if (
          typeof action !== 'object' ||
          !action.hasOwnProperty('type') ||
          action.type !== _actionTypes.TRANSITION_TO
        ) {
          return nextStore.dispatch(action);
        }

        return router.run(action.pathname, action.query);
      }

      // register listeners
      router.addChangeStartListener(function (
        currentRoute,
        resolvedRoute /* , router */
      ) {
        nextStore.dispatch(_actions.changeStart(currentRoute, resolvedRoute));
      });

      router.addChangeSuccessListener(function (resolvedRoute) {
        nextStore.dispatch(_actions.changeSuccess(resolvedRoute));
      });

      router.addChangeFailListener(function (
        error,
        previousRoute /* , router */
      ) {
        nextStore.dispatch(_actions.changeFail(previousRoute, error));
      });

      router.addNotFoundListener(function (path, query) {
        nextStore.dispatch(_actions.notFound(path, query));
      });

      // wrap handlers
      router.wrapOnEnterHandler(function (onEnter) {
        return onEnter(dispatch, nextStore.getState);
      });

      router.wrapOnLeaveHandler(function (onLeave) {
        return onLeave(dispatch, nextStore.getState);
      });

      // initial run of router
      // this is not needed because history.listen will be called with location
      // so pop state event will be handled
      // router.run(history.pathname(), history.query());
      router.listen(); // register popState listener

      return _extends({}, nextStore, {
        dispatch,
        router
      });
    };
  };

  const reducer = function reducer(state, action) {
    if (state === undefined) state = initialReducerState;

    switch (action.type) {
      case _actionTypes.ROUTE_CHANGE_START:
        return {
          state: 'TRANSITIONING',
          nextRoute: action.nextRoute,
          route: state.route
        };
      case _actionTypes.ROUTE_CHANGE_SUCCESS:
        return {
          state: 'TRANSITIONED',
          route: action.route
        };
      case _actionTypes.ROUTE_CHANGE_FAIL:
        return {
          state: 'TRANSITIONED',
          route: action.route, // will be set to previous route
          error: action.error
        };
      /*
             todo: not found make as only action which can user listen to and make redirects?
             case 'ROUTE_NOT_FOUND':
             return {
             state: 'TRANSITIONED',
             route: action.route // set to previous route
             }; */
      default:
        return state;
    }
  };

  return {
    router,
    store,
    reducer: { router: reducer }
  };
}

module.exports = exports.default;
