import React, { PropTypes } from 'react';
import { validate } from './validate';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field } from 'redux-form';

import { Button } from './../../../components';

import { renderInput } from './../../../forms/actions';
import { normalizeError } from './../../../helpers';

const form = 'createUser';

let CreateUserForm = (props) => {
    const { handleSubmit, isLoading, formError } = props;

    return (
        <form autoComplete="off" onSubmit={handleSubmit}>
            <div style={{ margin: '3em 0' }}>
                <p><FormattedMessage id="ADD_USER_HINT" /></p>
                <Field name={'id'} component={renderInput} type="input" />

                {formError &&
                    <div className="error" style={{ textAlign: 'center' }}>
                        <FormattedMessage id={normalizeError(formError.error_code)} />
                    </div>
                }
            </div>
            <div style={{ textAlign: 'center' }}>
                <Button
                    type="button"
                    onClick={handleSubmit}
                    disabled={isLoading}
                >
                    <FormattedMessage id={isLoading ? 'LOADING' : 'INVITE_USER'} />
                </Button>
            </div>
        </form>
    );
};

CreateUserForm.propTypes = {
    isLoading: PropTypes.bool,
    change: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    formError: PropTypes.object
};

CreateUserForm = connect(
    state => ({
        isLoading: state.users.get('createUserLoading'),
        formError: state.users.get('createUserError')
    })
)(CreateUserForm);


export default reduxForm({
    form,
    validate
})(CreateUserForm);

