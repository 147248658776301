import request from 'superagent-bluebird-promise';
import {
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE
} from '../../constants/actionTypes';

/**
 * Logout action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function logout() {
    return {
        types: [LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE],
        promise: request
                    .post(`${process.env.REACT_APP_API_URL}/api/v1/oauth/revoke?call=${Date.now()}`)
                    .set('Authorization', `Bearer ${sessionStorage.getItem('access_token')}`)
    };
}
