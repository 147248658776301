import request from 'superagent-bluebird-promise';
import {
    FETCH_QUIZ_REQUEST,
    FETCH_QUIZ_SUCCESS,
    FETCH_QUIZ_FAILURE
} from '../../constants/actionTypes';

/**
 * Fetch quiz action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function fetchQuiz(id) {
    return {
        types: [FETCH_QUIZ_REQUEST, FETCH_QUIZ_SUCCESS, FETCH_QUIZ_FAILURE],
        promise: request
                    .get(`${process.env.REACT_APP_API_URL}/api/v1/user/course/${id}/quiz?call=${Date.now()}`)
                    .set('Authorization', `Bearer ${sessionStorage.getItem('access_token')}`),
        id
    };
}
