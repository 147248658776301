// Vendor
import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import { Modal } from './../../../../components';
import { CreateUser } from './../../../../forms/admin';

// Actions
import { createUser } from './../../../../actions/user';

/**
 * Add user modal
 * @class
 */
class AddUserModal extends Component {
    /**
     * Handle submit
     * @param data
     */
    handleSubmit(data) {
        const { createUser } = this.props;
        const { removeModal } = this.context;

        createUser(data.id).then(
            (action) => {
                if (!action.error) {
                    removeModal();
                }
            }
        );
    }

    /**
     * Render
     * @returns {XML}
     */
    render() {
        const { removeModal } = this.context;

        return (
            <Modal
                heading="ADD_USER"
                onClose={removeModal}
            >
                <CreateUser onSubmit={this.handleSubmit.bind(this)} />
            </Modal>
        );
    }
}

// Prop types
AddUserModal.propTypes = {
    createUser: PropTypes.func.isRequired
};

// Context types
AddUserModal.contextTypes = {
    removeModal: PropTypes.func.isRequired
};

// Connect
export default connect(
    undefined,
    dispatch => (
        bindActionCreators({ createUser }, dispatch)
    )
)(AddUserModal);
