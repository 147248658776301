export const validate = values => {
    const errors = {};

    if (!values.intro_passed) {
        if (!values.password) {
            errors.password = 'REQUIRED';
        }

        if (values.password) {
            if (values.password.length < 8) {
                errors.password = 'SHORT_PASSWORD';
            }

            if (values.password !== values.password_again) {
                errors.password_again = 'PASSWORD_NOT_MATCH';
            }
        }
    }

    if (values.name) {
        if (values.name.length > 39) {
            errors.name = 'LONG_VALUE';
        }
    }

    if (values.office) {
        if (values.office.length > 31) {
            errors.office = 'LONG_VALUE';
        }
    }

    if (values.region) {
        if (values.region.length > 31) {
            errors.region = 'LONG_VALUE';
        }
    }

    return errors;
};
