import React, { Component, PropTypes } from 'react';
import Helmet from "react-helmet";
import classNames from 'classnames';
import { actions } from '../../../../routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import { Container, Header, Logo } from './../../components';
import EditModal from './../User/Main/components/UserInfoTable/EditModal';

import { logout } from './../../actions/auth';
import { fetchUsers } from './../../actions/user';

import Main from './Main';
import Compare from './Compare';

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false
        };
    }

    componentDidMount() {
        const { fetchUsers, loggedUser } = this.props;
        fetchUsers();

        if (loggedUser.get('role') === 'user') {
            if (!loggedUser.get('intro_passed')) {
                setTimeout(() => {
                    this.showEditModal.bind(this)();
                }, 100);
            }
        }
    }

    showEditModal() {
        const { addModal, removeModal } = this.context;

        addModal(<EditModal onClose={removeModal} />);
    }

    goTo(path) {
        const { transitionTo } = this.props;
        transitionTo(path);
        this.setState({ active: false });
    }

    render() {
        const { children, route, logoutLoading, logout, userId, usersLoading } = this.props;
        const { active } = this.state;
        const key = !usersLoading
            ? <FormattedMessage id={route === '/' ? 'COMPARE' : 'YOUR_DEVELOPMENT'} />
            : <FormattedMessage id="LOADING" />;

        return (
            <div style={{ marginBottom: '2em' }}>
                <Header
                    loggedIn
                    left={<Logo />}
                    right={
                        <span>
                            <button
                                className={classNames('hamburger', active && 'active')}
                                onClick={() => { this.setState({ active: !active }); }}
                            />
                            <span className={classNames('header-buttons', active && 'active')}>
                                <span
                                    className="link"
                                    onClick={this.goTo.bind(this, route === '/' ? '/compare' : '/')}
                                >
                                    {key}
                                </span>
                                <span className="id">
                                    {userId}
                                </span>
                                <button
                                    onClick={() => { logout(); }}
                                    disabled={logoutLoading}
                                    className="logout-button"
                                >
                                    <FormattedMessage id={'LOGOUT'} />
                                </button>

                                <span className={classNames('id', 'mobile')}>
                                    {userId}
                                </span>
                            </span>
                        </span>
                    }
                />

                <Container>
                    {children}
                </Container>

                <Helmet title={`${process.env.REACT_APP_TITLE} - User zóna`} />
            </div>
        );
    }
}

User.Main = Main;
User.Compare = Compare;

// Context types
User.contextTypes = {
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired
};

// Prop Types
User.propTypes = {
    children: PropTypes.any,
    logout: PropTypes.func.isRequired,
    logoutLoading: PropTypes.bool,
    userId: PropTypes.string,
    route: PropTypes.string,
    transitionTo: PropTypes.func.isRequired,
    usersLoading: PropTypes.bool,
    fetchUsers: PropTypes.func.isRequired,
    loggedUser: PropTypes.object
};

// Connect
export default connect(
    (state) => ({
        logoutLoading: state.auth.get('logoutLoading'),
        usersLoading: state.users.get('usersLoading'),
        route: state.router.route.pathname,
        loggedUser: state.users.get('user'),
        userId: state.users.get('user').get('name') || state.users.get('user').get('username')
    }),
    (dispatch) => {
        const transitionTo = actions.transitionTo;
        return bindActionCreators({ logout, fetchUsers, transitionTo }, dispatch);
    }
)(User);
