import request from 'superagent-bluebird-promise';
import {
    CREATE_USER_REQUEST,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE
} from '../../constants/actionTypes';

/**
 * Create user action
 *
 * @param{Number} userId
 * @returns {{types: *[], promise: Request }}
 */

export default function createUser(userId) {
    return {
        types: [CREATE_USER_REQUEST, CREATE_USER_SUCCESS, CREATE_USER_FAILURE],
        promise: request
                    .post(`${process.env.REACT_APP_API_URL}/api/v1/user?call=${Date.now()}`)
                    .set('Authorization', `Bearer ${sessionStorage.getItem('access_token')}`)
                    .send({
                        tb: userId
                    })
    };
}
