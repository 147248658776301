// Auth
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

// User
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

export const EDIT_USER_REQUEST = "EDIT_USER_REQUEST";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILURE = "EDIT_USER_FAILURE";

export const FETCH_COURSE_REQUEST = "FETCH_COURSE_REQUEST";
export const FETCH_COURSE_SUCCESS = "FETCH_COURSE_SUCCESS";
export const FETCH_COURSE_FAILURE = "FETCH_COURSE_FAILURE";

export const FETCH_QUIZ_REQUEST = "FETCH_QUIZ_REQUEST";
export const FETCH_QUIZ_SUCCESS = "FETCH_QUIZ_SUCCESS";
export const FETCH_QUIZ_FAILURE = "FETCH_QUIZ_FAILURE";

export const EVALUATE_QUIZ_REQUEST = "EVALUATE_QUIZ_REQUEST";
export const EVALUATE_QUIZ_SUCCESS = "EVALUATE_QUIZ_SUCCESS";
export const EVALUATE_QUIZ_FAILURE = "EVALUATE_QUIZ_FAILURE";

export const RESET_QUIZ_REQUEST = "RESET_QUIZ_REQUEST";
export const RESET_QUIZ_SUCCESS = "RESET_QUIZ_SUCCESS";
export const RESET_QUIZ_FAILURE = "RESET_QUIZ_FAILURE";

export const FILTER_USERS = "FILTER_USERS";

// ADMIN

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";

export const SET_SECTION_DATA_REQUEST = "SET_SECTION_DATA_REQUEST";
export const SET_SECTION_DATA_SUCCESS = "SET_SECTION_DATA_SUCCESS";
export const SET_SECTION_DATA_FAILURE = "SET_SECTION_DATA_FAILURE";

export const SET_NOTICE_REQUEST = "SET_NOTICE_REQUEST";
export const SET_NOTICE_SUCCESS = "SET_NOTICE_SUCCESS";
export const SET_NOTICE_FAILURE = "SET_NOTICE_FAILURE";

export const GET_COURSES_REQUEST = "GET_COURSES_REQUEST";
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSES_FAILURE = "GET_COURSES_FAILURE";

export const EDIT_COURSE_REQUEST = "EDIT_COURSE_REQUEST";
export const EDIT_COURSE_SUCCESS = "EDIT_COURSE_SUCCESS";
export const EDIT_COURSE_FAILURE = "EDIT_COURSE_FAILURE";

export const CREATE_COURSE_REQUEST = "CREATE_COURSE_REQUEST";
export const CREATE_COURSE_SUCCESS = "CREATE_COURSE_SUCCESS";
export const CREATE_COURSE_FAILURE = "CREATE_COURSE_FAILURE";

export const ARCHIVE_COURSE_REQUEST = "ARCHIVE_COURSE_REQUEST";
export const ARCHIVE_COURSE_SUCCESS = "ARCHIVE_COURSE_SUCCESS";
export const ARCHIVE_COURSE_FAILURE = "ARCHIVE_COURSE_FAILURE";

export const FETCH_QUESTIONS_REQUEST = "FETCH_QUESTIONS_REQUEST";
export const FETCH_QUESTIONS_SUCCESS = "FETCH_QUESTIONS_SUCCESS";
export const FETCH_QUESTIONS_FAILURE = "FETCH_QUESTIONS_FAILURE";

export const CREATE_QUESTION_REQUEST = "CREATE_QUESTION_REQUEST";
export const CREATE_QUESTION_SUCCESS = "CREATE_QUESTION_SUCCESS";
export const CREATE_QUESTION_FAILURE = "CREATE_QUESTION_FAILURE";

export const UPDATE_QUESTION_REQUEST = "UPDATE_QUESTION_REQUEST";
export const UPDATE_QUESTION_SUCCESS = "UPDATE_QUESTION_SUCCESS";
export const UPDATE_QUESTION_FAILURE = "UPDATE_QUESTION_FAILURE";

export const UPDATE_ANSWER_REQUEST = "UPDATE_ANSWER_REQUEST";
export const UPDATE_ANSWER_SUCCESS = "UPDATE_ANSWER_SUCCESS";
export const UPDATE_ANSWER_FAILURE = "UPDATE_ANSWER_FAILURE";

export const DELETE_QUESTION_REQUEST = "DELETE_QUESTION_REQUEST";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";
export const DELETE_QUESTION_FAILURE = "DELETE_QUESTION_FAILURE";

export const UPLOAD_FILES_REQUEST = "UPLOAD_FILES_REQUEST";
export const UPLOAD_FILES_SUCCESS = "UPLOAD_FILES_SUCCESS";
export const UPLOAD_FILES_FAILURE = "UPLOAD_FILES_FAILURE";

export const FILTER_QUESTIONS = "FILTER_QUESTIONS";
