exports.__esModule = true;

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      `Super expression must either be null or a function, not ${typeof superClass}`
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

const NoRoutesToResolveError = (function (_Error) {
  _inherits(NoRoutesToResolveError, _Error);

  function NoRoutesToResolveError() {
    _classCallCheck(this, NoRoutesToResolveError);

    _Error.apply(this, arguments);
  }

  return NoRoutesToResolveError;
})(Error);

exports.NoRoutesToResolveError = NoRoutesToResolveError;

const RouteNotFoundError = (function (_Error2) {
  _inherits(RouteNotFoundError, _Error2);

  function RouteNotFoundError() {
    _classCallCheck(this, RouteNotFoundError);

    _Error2.apply(this, arguments);
  }

  return RouteNotFoundError;
})(Error);

exports.RouteNotFoundError = RouteNotFoundError;
