import React, { Component, PropTypes } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

export default class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                active: true
            });
        }, 100);

        document.body.style.top = `${-document.body.scrollTop}px`;
        document.body.className = document.body.className + ' open';
    }

    componentWillUnmount() {
        document.body.className = document.body.className.replace('open', '');
        window.scrollTo(0, -1 * parseInt(document.body.style.top, 10));
    }

    render() {
        const { onClose, children, heading, large } = this.props;
        const { active } = this.state;

        return (
            <div className="modal-wrapper">
                <div className="modal-owerflow" onClick={onClose}></div>
                <div className={classNames('modal', active && 'active', large && 'large')}>
                    {heading && <h4><FormattedMessage id={heading} /></h4>}
                    <button className="modal__close-button" onClick={onClose} />
                    <div className="modal__content">
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

Modal.propTypes = {
    children: PropTypes.any.isRequired,
    onClose: PropTypes.func.isRequired,
    heading: PropTypes.string,
    large: PropTypes.bool
};
