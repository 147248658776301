exports.__esModule = true;
exports.default = createView;

function _objectWithoutProperties(obj, keys) {
  const target = {};
  for (const i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      `Super expression must either be null or a function, not ${typeof superClass}`
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

const _PropTypes = require('prop-types');

function createView(React, connect) {
  const { Component } = React;
  const PropTypes = _PropTypes;
  const { isValidElement } = React;

  const View = (function (_Component) {
    _inherits(View, _Component);

    function View() {
      _classCallCheck(this, View);

      _Component.apply(this, arguments);
    }

    View.prototype.render = function render() {
      const _props = this.props;
      const { state } = _props;
      const { route } = _props;

      const props = _objectWithoutProperties(_props, ['state', 'route']);

      if (state === 'INITIAL' || !route || !route.components) {
        return null;
      }

      return route.components.reduceRight(function (component, parent) {
        if (component === null) {
          return React.createElement(parent, props);
        }

        const child = isValidElement(component)
          ? component
          : React.createElement(component, props);

        return React.createElement(parent, props, child);
      }, null);
    };

    return View;
  })(Component);

  View.propTypes = {
    state: PropTypes.oneOf(['INITIAL', 'TRANSITIONING', 'TRANSITIONED'])
      .isRequired,
    route: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      query: PropTypes.object.isRequired,
      vars: PropTypes.object.isRequired,
      components: PropTypes.array.isRequired
    })
  };

  View.contextTypes = {
    store: PropTypes.object.isRequired
  };

  return connect(function (state) {
    return state.router;
  })(View);
}

module.exports = exports.default;
