import React, { Component, PropTypes } from 'react';
import classNames from 'classnames';

export default class TextArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false
        };
    }

    render() {
        const { field, type, error } = this.props;
        const { active } = this.state;

        field.onFocus = () => { this.setState({ active: true }); };
        field.onBlur = () => { this.setState({ active: false }); };

        return (
            <div
                className={classNames('input-text-area-component', active && 'active', error && 'error')}
                onClick={() => { this.input.focus(); }}
            >
                <textarea
                    className={classNames(active && 'active')}
                    type={type}
                    autoComplete="off"
                    ref={(input) => (this.input = input)}
                    {...field}
                />
            </div>
        );
    }
}

TextArea.propTypes = {
    field: PropTypes.object.isRequired,
    placeholder: PropTypes.string.isRequired,
    type: PropTypes.string,
    error: PropTypes.bool
};
