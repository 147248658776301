import request from 'superagent-bluebird-promise';
import {
    UPLOAD_FILES_REQUEST,
    UPLOAD_FILES_SUCCESS,
    UPLOAD_FILES_FAILURE
} from '../../constants/actionTypes';

/**
 * Upload files action
 *
 * @param{Array} files
 * @returns {{types: *[], promise: Request }}
 */
export default function uploadFiles(files) {
    const data = new FormData();
    data.append('organization_struct', files[0]);
    data.append('participants', files[1]);
    data.append('managers', files[2]);

    return {
        types: [UPLOAD_FILES_REQUEST, UPLOAD_FILES_SUCCESS, UPLOAD_FILES_FAILURE],
        promise: request
                    .put(`${process.env.REACT_APP_API_URL}/api/v1/user/creation`)
                    .set('Authorization', `Bearer ${sessionStorage.getItem('access_token')}`)
                    .send(data)
    };
}
