import request from 'superagent-bluebird-promise';
import {
    FETCH_QUESTIONS_REQUEST,
    FETCH_QUESTIONS_SUCCESS,
    FETCH_QUESTIONS_FAILURE
} from '../../constants/actionTypes';

/**
 * Fetch questions action
 *
 * @param{Number} id
 * @returns {{types: *[], promise: Request }}
 */
export default function fetchQuestions(id) {
    return {
        types: [FETCH_QUESTIONS_REQUEST, FETCH_QUESTIONS_SUCCESS, FETCH_QUESTIONS_FAILURE],
        promise: request
                    .get(`${process.env.REACT_APP_API_URL}/api/v1/course/${id}/questions`)
                    .set('Authorization', `Bearer ${sessionStorage.getItem('access_token')}`),
        id
    };
}
