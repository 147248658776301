import React, { PropTypes } from 'react';
import classNames from 'classnames';

const EditButton = (props) => ((
    <button
        onClick={props.onClick}
        className={classNames('edit-button-component', props.className)}
        style={props.style}
    />
));

EditButton.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object
};

export default EditButton;
