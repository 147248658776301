import request from "superagent-bluebird-promise";
import {
  RESET_QUIZ_REQUEST,
  RESET_QUIZ_SUCCESS,
  RESET_QUIZ_FAILURE,
} from "../../constants/actionTypes";

export default function resetQuiz(courseId, userId) {
  return {
    types: [RESET_QUIZ_REQUEST, RESET_QUIZ_SUCCESS, RESET_QUIZ_FAILURE],
    promise: request
      .get(
        userId == null
          ? `${process.env.REACT_APP_API_URL}/api/v1/user/course/${courseId}/quiz/reset`
          : `${process.env.REACT_APP_API_URL}/api/v1/user/${userId}/course/${courseId}/quiz/reset`
      )
      .set("Authorization", `Bearer ${sessionStorage.getItem("access_token")}`),
    courseId,
    userId,
  };
}
