import request from 'superagent-bluebird-promise';
import {
    SET_NOTICE_REQUEST,
    SET_NOTICE_SUCCESS,
    SET_NOTICE_FAILURE
} from '../../constants/actionTypes';

/**
 * Set section data action
 *
 * @param{Number} userId
 * @param{Number} courseId
 * @param{Number} value
 * @returns {{types: *[], promise: Request }}
 */

export default function setNotice(userId, courseId, value) {
    return {
        types: [SET_NOTICE_REQUEST, SET_NOTICE_SUCCESS, SET_NOTICE_FAILURE],
        promise: request
                    .put(`${process.env.REACT_APP_API_URL}/api/v1/user/${userId}/course/${courseId}?call=${Date.now()}`)
                    .set('Authorization', `Bearer ${sessionStorage.getItem('access_token')}`)
                    .send({
                        notice: value
                    }),
        userId, courseId, value
    };
}
