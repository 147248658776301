import request from 'superagent-bluebird-promise';
import {
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE
} from '../../constants/actionTypes';

/**
 * Forgot password action
 *
 * @username {String}
 * @returns {{types: *[], promise: Request }}
 */

export default function forgotPassword(username) {
    return {
        types: [FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILURE],
        promise: request
                    .post(`${process.env.REACT_APP_API_URL}/api/v1/forgot_password?call=${Date.now()}`)
                    .send({ username })
    };
}
