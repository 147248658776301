// Vendor
import React, { Component } from 'react';

/**
 * Modal system
 * @class
 */
export default class ModalSystem extends Component {
    /**
     * Constructor
     * @param props
     * @param context
     */
    constructor(props, context) {
        super(props, context);
        this.state = {
            modals: []
        };
    }

    /**
     * Add modal
     * @param modal
     */
    addModal(modal) {
        this.setState({ modals: this.state.modals.concat([modal]) });
    }

    /**
     * Remove modal
     */
    removeModal() {
        const modals = this.state.modals;
        modals.pop();
        this.setState({ modals });
    }

    /**
     * Render
     * @returns {XML}
     */
    render() {
        const { modals } = this.state;

        return (
            <div>
                {modals.map((modal, i) => (
                    <span
                        key={i}
                        style={{
                            position: 'relative',
                            zIndex: `'{${999 + i}}'`
                        }}
                    >
                        {modal}
                    </span>
                ))}
            </div>
        );
    }
}
