exports.__esModule = true;
exports.resolveWithFirstMatched = resolveWithFirstMatched;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

const _invariant = require('invariant');

const _invariant2 = _interopRequireDefault(_invariant);

const _Route = require('../Route');

const _Route2 = _interopRequireDefault(_Route);

const _errors = require('../errors');

/**
 * Returns first resolved route, if none resolve, rejects
 *
 * Routes are resolved in order
 *
 * @param {Array} routes
 * @param {string} path
 * @param {query} query
 * @returns {Promise}
 */

function resolveWithFirstMatched(routes, path, query) {
  if (routes === undefined) routes = [];

  _invariant2.default(
    Array.isArray(routes),
    `Routes should be an array, ${typeof routes} given.`
  );

  function runAndResolveOnFirstResolved(promises, _resolve, _reject) {
    const currentIndex =
      arguments.length <= 3 || arguments[3] === undefined ? 0 : arguments[3];

    const route = promises[currentIndex];

    _invariant2.default(
      route instanceof _Route2.default,
      `Routes should contain only Route objects, ${typeof route} given at index ${currentIndex}`
    );

    const result = route.match(path, query);

    result.then(_resolve, function (err) {
      if (currentIndex === routes.length - 1) {
        _reject(err);
      } else {
        runAndResolveOnFirstResolved(
          promises,
          _resolve,
          _reject,
          currentIndex + 1
        );
      }
    });
  }

  return new Promise(function (resolve, reject) {
    // call routes in order
    if (!routes.length) {
      return reject(new _errors.NoRoutesToResolveError('No routes to resolve'));
    }

    return runAndResolveOnFirstResolved(routes, resolve, reject);
  });
}
