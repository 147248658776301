export const validate = (values, { courseId, courses }) => {
  const errors = {};

  if (!values.theme_name) {
    errors.theme_name = "REQUIRED";
  }

  if (values.theme_name) {
    if (values.theme_name.length > 31) {
      errors.theme_name = "LONG_VALUE";
    }
  }

  if (!values.adviser) {
    errors.adviser = "REQUIRED";
  }

  if (values.adviser) {
    if (values.adviser.length > 63) {
      errors.adviser = "LONG_VALUE";
    }
  }

  if (!values.place) {
    errors.place = "REQUIRED";
  }

  if (values.place) {
    if (values.place.length > 63) {
      errors.place = "LONG_VALUE";
    }
  }

  if (!values.date) {
    errors.date = "REQUIRED";
  }

  if (!values.task) {
    errors.task = "REQUIRED";
  }

  if (courseId == null) {
    const activeCourses = courses?.filter((course) => course.get("active"));
    const maxOrderValue = Number((activeCourses?.size || 0) + 1);

    if (!values.order) {
      errors.order = "REQUIRED";
    }

    if (values.order < 1 || values.order > maxOrderValue) {
      errors.order = `ORDER_INVALID_VALUE_${maxOrderValue}`;
    }
  }

  return errors;
};
