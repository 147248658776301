/**
 * @author Ján Kertis <kertis.jan.15@gmail.com>
 * @copyright Ján Kertis, 2016
 */

// Vendor
import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';

// Components
import { ButtonGroup } from './../../components';

// Actions
import { renderInput } from './../../actions';

// Const
import { validate } from './validate';

import '../../styles.css'

/**
 * Forgot password form
 * @param props
 * @returns {XML}
 * @constructor
 */
let ForgotPasswordForm = (props) => {
    const { handleSubmit, isLoading, forgotPasswordError } = props;

    return (
        <form autoComplete="off" className="form" onSubmit={handleSubmit}>
            <Field name="username" component={renderInput} fieldError={forgotPasswordError} />

            <ButtonGroup>
                <button type="submit" onClick={handleSubmit} disabled={isLoading}>
                    <FormattedMessage id={isLoading ? 'LOADING' : 'SEND'} />
                </button>
            </ButtonGroup>
        </form>
    );
};

ForgotPasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    forgotPasswordError: PropTypes.bool
};

ForgotPasswordForm = reduxForm({
    form: 'forgotPassword',
    validate
})(ForgotPasswordForm);

ForgotPasswordForm = connect(
    state => ({
        forgotPasswordError: state.auth.get('forgotPasswordError')
    })
)(ForgotPasswordForm);

export default ForgotPasswordForm;
