import request from 'superagent-bluebird-promise';
import {
    GET_COURSES_REQUEST,
    GET_COURSES_SUCCESS,
    GET_COURSES_FAILURE
} from '../../constants/actionTypes';

/**
 * Get courses action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function getCourses() {
    return {
        types: [GET_COURSES_REQUEST, GET_COURSES_SUCCESS, GET_COURSES_FAILURE],
        promise: request
                    .get(`${process.env.REACT_APP_API_URL}/api/v1/courses?call=${Date.now()}`)
                    .set('Authorization', `Bearer ${sessionStorage.getItem('access_token')}`)
    };
}
