import request from 'superagent-bluebird-promise';
import {
    UPDATE_QUESTION_REQUEST,
    UPDATE_QUESTION_SUCCESS,
    UPDATE_QUESTION_FAILURE
} from '../../constants/actionTypes';

/**
 * Update question action
 *
 * @param{Number} id
 * @param{Number} questionId
 * @param{Object} data
 * @returns {{types: *[], promise: Request }}
 */
export default function updateQuestion(id, questionId, data) {
    return {
        types: [UPDATE_QUESTION_REQUEST, UPDATE_QUESTION_SUCCESS, UPDATE_QUESTION_FAILURE],
        promise: request
                    .put(`${process.env.REACT_APP_API_URL}/api/v1/course/${id}/question/${questionId}`)
                    .set('Authorization', `Bearer ${sessionStorage.getItem('access_token')}`)
                    .send(data),
        id, questionId
    };
}
