// Vendor
import React, { Component, PropTypes } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import { Button, Modal } from './../../../../../components';

// Actions
import { fetchQuiz } from './../../../../../actions/user';

/**
 * Quiz modal
 * @class
 */
class QuizModal extends Component {
    /**
     * Submit
     */
    submit() {
        const { fetchQuiz, courseId, scrollTo, onSubmit } = this.props;
        const { removeModal } = this.context;

        fetchQuiz(courseId).then(
            (action) => {
                if (!action.error) {
                    scrollTo();
                    onSubmit();
                }
                removeModal();
            }
        );
    }

    /**
     * Render
     * @returns {XML}
     */
    render() {
        const { courseId, coursesLoading } = this.props;
        const { removeModal } = this.context;

        const isLoading = coursesLoading.get('quiz') && coursesLoading.get('quiz').get(courseId);

        return (
            <Modal onClose={removeModal}>
                <p>
                    <FormattedHTMLMessage id="QUIZ_MODAL_INFO" />
                </p>
                <div style={{ textAlign: 'center', margin: '3em 0 1em 0' }}>
                    <Button
                        disabled={isLoading}
                        onClick={this.submit.bind(this)}
                    >
                        <FormattedMessage id={isLoading ? 'LOADING' : 'START_QUIZ'} />
                    </Button>
                </div>
            </Modal>
        );
    }
}

// Prop types
QuizModal.propTypes = {
    fetchQuiz: PropTypes.func.isRequired,
    scrollTo: PropTypes.func,
    courseId: PropTypes.string.isRequired,
    coursesLoading: PropTypes.object,
    onSubmit: PropTypes.func.isRequired
};

// Context types
QuizModal.contextTypes = {
    removeModal: PropTypes.func.isRequired
};

export default connect(
    state => ({
        coursesLoading: state.users.get('coursesLoading')
    }),
    dispatch => (
        bindActionCreators({ fetchQuiz }, dispatch)
    )
)(QuizModal);
