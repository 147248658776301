export const validate = values => {
    const errors = {};

    if (!values.value) {
        errors.value = 'REQUIRED';
    }

    if (values.value) {
        const value = Number(values.value);
        if (value !== Number(value.toFixed())) {
            errors.value = 'WRONG_VALUE';
        }
    }

    if (values.sectionId === 1) {
        if (values.value) {
            if (values.value > 60 || values.value < 0) {
                errors.value = 'WRONG_VALUE';
            }
        }
    } else {
        if (values.value) {
            if (values.value > 20 || values.value < 0) {
                errors.value = 'WRONG_VALUE';
            }
        }
    }

    return errors;
};
