import request from 'superagent-bluebird-promise';
import {
    UPDATE_ANSWER_REQUEST,
    UPDATE_ANSWER_SUCCESS,
    UPDATE_ANSWER_FAILURE
} from '../../constants/actionTypes';

/**
 * Update answer action
 *
 * @param{Number} id
 * @param{Number} questionId
 * @param{Number} answerId
 * @param{Object} data
 * @returns {{types: *[], promise: Request }}
 */
export default function updateAnswer(id, questionId, answerId, data) {
    return {
        types: [UPDATE_ANSWER_REQUEST, UPDATE_ANSWER_SUCCESS, UPDATE_ANSWER_FAILURE],
        promise: request
                    .put(`${process.env.REACT_APP_API_URL}/api/v1/course/${id}/question/${questionId}/answer/${answerId}`)
                    .set('Authorization', `Bearer ${sessionStorage.getItem('access_token')}`)
                    .send(data),
        id, questionId, answerId
    };
}
