import request from 'superagent-bluebird-promise';
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE
} from '../../constants/actionTypes';
import sha1 from 'sha1';

/**
 * Login action
 *
 * @username {String}
 * @password {String}
 * @returns {{types: *[], promise: Request }}
 */

export default function login(username, password) {
    const hashedPassword = sha1(`${password}${process.env.REACT_APP_PASSWORD_HASH}`);
    return {
        types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
        promise: request
            .post(`${process.env.REACT_APP_API_URL}/api/v1/oauth/token?call=${Date.now()}`)
            .send({
                username,
                password: hashedPassword,
                grant_type: 'password',
                client_id: process.env.REACT_APP_CLIENT_ID,
                client_secret: process.env.REACT_APP_CLIENT_SECRET
            })
    };
}
