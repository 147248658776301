import request from "superagent-bluebird-promise";
import {
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
} from "../../constants/actionTypes";
import sha1 from "sha1";

/**
 * Edit user action
 *
 * @param{Object} user
 * @returns {{types: *[], promise: Request }}
 */

export default function editUser(user) {
  const editedUser = new FormData();
  if (user.name) {
    editedUser.append("name", user.name);
  }
  if (user.office) {
    editedUser.append("office", user.office);
  }
  if (user.region) {
    editedUser.append("region", user.region);
  }

  if (user.password) {
    const hashedPassword = sha1(
      `${user.password}${process.env.REACT_APP_PASSWORD_HASH}`
    );
    editedUser.append("password", hashedPassword);
  }

  if (user.image) {
    editedUser.append("image", user.image);
  }

  return {
    types: [EDIT_USER_REQUEST, EDIT_USER_SUCCESS, EDIT_USER_FAILURE],
    promise: request
      .put(`${process.env.REACT_APP_API_URL}/api/v1/user?call=${Date.now()}`)
      .set("Authorization", `Bearer ${sessionStorage.getItem("access_token")}`)
      .send(editedUser),
  };
}
