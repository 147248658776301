import React, { Component, PropTypes } from 'react';
import classNames from 'classnames';
import { actions } from '../../../../../../routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import { EditButton } from './../../../../components';

@connect(
    state => ({
        courses: state.users.get('adminCourses').filter((c) => c.get('active')).toArray()
    }),
    dispatch => {
        const transitionTo = actions.transitionTo;
        return bindActionCreators({ transitionTo }, dispatch);
    }
)
class QuizThemesTable extends Component {
    static propTypes = {
        courses: PropTypes.array.isRequired,
        transitionTo: PropTypes.func.isRequired
    };

    redirect = (path) => {
        const { transitionTo } = this.props;
        transitionTo(path);
    };

    render() {
        const { courses } = this.props;

        return (
            <div>
                {courses.map((course, i) => (
                    <div
                        key={i}
                        className={classNames('quiz-theme-row', `theme${course.get('order')}`)}
                    >
                        <div className="left">
                            <EditButton
                                onClick={() => this.redirect(`/admin/quiz/${course.get('id')}`)}
                            />
                        </div>

                        <div className="top">
                            <span className="row-item">
                                <span className="title">{course.get('name')}</span>
                                <FormattedMessage id="THEME_TITLE" />
                            </span>
                        </div>

                        <div className="right">
                            <FormattedMessage id="DEFINED_QUESTIONS" />
                            <span className="blue">
                                {course.get('question_count')}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

export default QuizThemesTable
