import React, { PropTypes } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

const renderInput = (field, context) => ((
    <div>
        <input
            autoComplete="off"
            {...field.input}
            type={field.type}
            placeholder={context.intl.formatMessage({ id: field.placeholder })}
            className={
                classNames(
                    'basic-input',
                    ((!field.noError && field.meta.touched && field.meta.error) || field.fieldError)
                    && 'error',
                    field.blue && 'blue'
                )
            }
        />
        {field.visibleError && field.meta.touched && field.meta.error &&
            <span className="error">
                <FormattedMessage id={`ERRORS.${field.meta.error}`} />
            </span>
        }
    </div>
));

renderInput.contextTypes = {
    intl: PropTypes.object.isRequired
};

export default renderInput;
