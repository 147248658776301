/* eslint-disable */
import { flattenObjectKeys } from "../helpers";

export default flattenObjectKeys({
  HOME: "Domov",
  LOGIN: "Prihlásenie",
  LOGOUT: "Odhlásiť",
  LOADING: "Načítavam...",
  EMAIL: "Email",
  FIELDS: {
    id: "Osobné číslo",
    username: "Osobné číslo",
    password: "Heslo",
    name: "Meno a Priezvisko",
    region: "Región",
    office: "Pobočka",
    value: "Hodnota v bodoch",
    theme_name: "Názov témy",
    adviser: "Lektor",
    place: "Miesto",
    start_date: "Dátum",
    task: "Zadanie",
    password_again: "Potvriť heslo",
    order: "Poradie",
  },
  NAME: "Meno a Priezvisko",
  OFFICE: "Pobočka",
  REGION: "Región",
  QUIZ: "Kvíz",
  CHAT: "Chat",
  FORGOT_PASSWORD: "Zabudnuté heslo",
  CONTACT_US_INFO: "V prípade problémov s prihlásením nás prosím kontaktujte",
  CONTACT_US: "Kontaktovať",
  COMPARE: "Porovnanie",
  DEVELOPMENT_DETAIL: "Detail rozvoja",
  MET: "Splnené na {progress} / {maximum}",
  TOTAL_SCORE: "Celkové skóre",
  FULFILLED_ASSIGNMENT: "Splnené zadanie",
  ASSIGNMENT: "Zadanie",
  THEME_NAME: "Názov témy",
  LECTOR: "Lektor",
  PLACE: "Miesto",
  DATE: "Dátum",
  PARTICIPATION: "Účasť na školení",
  ACTIVITY: "Aktivita na školení",
  START_QUIZ: "Spustiť kvíz",
  YOUR_DEVELOPMENT: "Váš rozvoj",
  ALL_USERS: "Všetci účastníci",
  EVALUATE: "Vyhodnotiť",
  LECTOR_EVALUATION: "Zhodnotenie lektora",
  ERROR: "Chyba",
  WRONG_DATA_ERROR: "Nesprávne údaje",
  REQUIRED: "Povinný údaj",
  QUIZ_MODAL_INFO:
    "Po spustení kvízu máte 10 min na jeho vyplnenie. Po uplynutí tejto doby sa kvíz automaticky uzavrie a body vám budú pripísané po jeho vyhodnotení.<br /><br /><br />Prajete si spustiť kvíz teraz?",
  ERRORS: {
    USER_NOT_IN_COURSE: "Nieste v kurze pre tento kvíz",
    ALREADY_GENERATED: "Kvíz už bol pre vaše konto vygenerovaný",
    NO_QUESTIONS: "Kvíz neobsahuje žiadne otázky",
    BAD_FILE: "Zlý súbor",
    SHORT_PASSWORD: "Heslo musí obsahovať minimálne 8 znakov",
    PASSWORD_NOT_MATCH: "Heslá sa nezhodujú",
    REQUIRED: "Povinný údaj",
    LONG_VALUE: "Údaj je príliš dlhý",
    Q: "Otázka musí mať 3 odpovede",
    ORDER_INVALID_VALUE_1: "Poradie musí byť číslo 1",
    ORDER_INVALID_VALUE_2: "Poradie musí byť číslo v rozmedzí 1-2",
    ORDER_INVALID_VALUE_3: "Poradie musí byť číslo v rozmedzí 1-3",
    ORDER_INVALID_VALUE_4: "Poradie musí byť číslo v rozmedzí 1-4",
    ORDER_INVALID_VALUE_5: "Poradie musí byť číslo v rozmedzí 1-5",
    ORDER_INVALID_VALUE_6: "Poradie musí byť číslo v rozmedzí 1-6",
  },
  QUIZ_BLOCK_INFO:
    "Tento kvíz obsahuje dokopy 20 otázok. Máte na výber z 3 odpovedí (A, B, C), každá otázka má len jednu odpoveď.",
  QUIZ_RESULTS: "Výsledok kvízu",
  QUESTIONS_COUNT: "Počet otázok",
  CORRECT_ANSWERS: "Správne odpovede",
  CLOSE: "Zatvoriť",

  ASSIGNMENT_INFO: "Úloha sa zobrazí 24h po absolvovaní školenia",
  QUIZ_GENERATED: "Kvíz bol absolvovaný",
  DATA_EDIT: "Editácia údajov",
  SAVE: "Uložiť",
  COUNT_OF_CORRECT_ANSWERS: "Počet správnych odpovedí",
  PARTICIPANTS: "Účastníci",
  THEMES: "Témy",
  ADD_USER: "Pridať uživateľa",
  SEARCH: "Vyhľadať",
  THEME_LIST: "Zoznam tém",
  DUPLICATE_THEME: "Duplikovať tému",
  ADD_THEME: "Pridať tému",
  SAVE_CHANGES: "Uložiť zmeny",
  THEME_TITLE: "Názov témy",
  NOT_STARTED_THEME: "Nezačatá téma",
  NO_CURSES: "Momentálne nieste zaradený v žiadnom kurze.",
  SECTION_MODAL_INFO:
    "Zadajte číselnú hodnotu (0-{max}) na základe vášho hodnotenia.",
  EVALUATION: "Zhodnotenie",
  NOTICE_INFO: "Žiadny komentár od lektora...",
  MY_TEAM: "Môj tím",
  REQUIRED_ALL_FIELDS: "Všetky polia sú povinné",
  ADD_USER_HINT:
    "Zadajte osobné číslo účastníka rozvoja. Prihlasovacie údaje obdrží vo forme emailu do niekoľkých minút.",
  USER_NOT_EXIST: "Používateľ neexistuje",
  INVITE_USER: "Pozvať účastníka",
  USER_ALREADY_EXIST: "Používateľ už existuje",
  BAD_ROLE: "Nepovolená operácia pre Vašu rolu",
  USER_NOT_IN_COURSE: "Používateľ nie je v žiadnom kurze.",
  SEND: "Odoslať",
  FIRST_LOGIN_INFO: "<h2>Vitajte.</h2><p>Vyplnte si vaše osobné údaje.</p>",
  ARCHIVED: "Archivované",
  NO_USERS: "Nenájdeny žiadny účastnik",
  UNKNOWN: "Nevyplnené",
  DATE_INFO: "Varovanie: Dátum začatia kurzu nesmie byť v minulosti",
  ORDER_INFO: "Varovanie: Ako poradie zadajte číslo v rozmedzí 1-6",
  DEFINED_QUESTIONS: "Definovaných otázok",
  QUESTIONS_LIST: "Zoznam otázok",
  SAVE_QUESTION: "Uložiť otázku",
  QUESTION: "Otázka",
  ANSWERS: "Odpovede",
  CORRECT_ANSWER: "Správna odpoveď",
  INCORRECT_ANSWER: "Nesprávna odpoveď",
  QUESTION_PLACEHOLDER: "Vašu otázku napíšte alebo skopírujte sem",
  UPDATE_QUESTION: "Editácia otázky",
  QUESTION_CREATED: "Otázka bola vytvorená",
  REMOVE: "Zmazať",
  INFO: "Info",
  UPLOAD_FILES: "Nahrať súbory",
  SUCCESSFULLY_UPLOADED:
    "Nahrávanie prebehlo úspešne. Teraz sa stránka znova načíta.",
  ERROR_OCCURRED: "Nastala chyba. Skúste to znova.",
  organization_struct: "Organizačná štruktúra",
  participants: "Účastníci",
  managers: "Nadriadení",
  LOCKED_COURSE: "Kurz je zamknutý",
  RESET: "Reset",
  SORT_BY: "Zoradiť podľa",
  FILTER_BY_ACTIVE_COURSE: "Aktívny kurz",
  VIEW_ANSWERS: "Náhľad",
  HIDE_ANSWERS: "Schovaj náhľad",
  CANCEL: "Zrušiť",
  ARCHIVE: "Archivuj",
  ACTIVE: "Aktívna",
  INACTIVE: "Neaktívna",
  NEW_THEME: "Nová téma",
  CREATE: "Vytvoriť",
});
