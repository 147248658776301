import {
    FILTER_USERS
} from './../../constants/actionTypes';

export default function filterUsers(value) {
    return {
        type: FILTER_USERS,
        value
    };
}
