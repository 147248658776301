import React, { PropTypes } from 'react';
import classNames from 'classnames';

const Button = (props) => ((
    <button
        className={
            classNames(
                'button',
                props.small && 'small',
                props.className,
                props.disabled && 'disabled'
            )
        }
        type={props.type || 'button'}
        style={props.style}
        onClick={props.onClick}
        disabled={props.disabled}
    >
        {props.children}
    </button>
));

Button.propTypes = {
    children: PropTypes.any,
    onClick: PropTypes.any,
    type: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    disabled: PropTypes.any,
    small: PropTypes.bool
};

export default Button;
