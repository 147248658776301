exports.__esModule = true;

const _extends =
  Object.assign ||
  function (target) {
    for (let i = 1; i < arguments.length; i++) {
      const source = arguments[i];
      for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

const _Route = require('./Route');

const _Route2 = _interopRequireDefault(_Route);

const _utilsRouteUtils = require('./utils/routeUtils');

const _utilsRouterUtils = require('./utils/routerUtils');

const _invariant = require('invariant');

const _invariant2 = _interopRequireDefault(_invariant);

const _errors = require('./errors');

const _utilsUrlUtils = require('./utils/urlUtils');

function instantiateRoutes(routes) {
  return routes.map(function (definition) {
    const normalized = _utilsRouteUtils.normalizeRouteDefinition(definition);

    return new _Route2.default(
      normalized.path,
      undefined,
      normalized.children,
      normalized.onEnter,
      normalized.onLeave,
      normalized.component,
      normalized.attrs
    );
  });
}

const REPLACE_STATE = 'replace';
const PUSH_STATE = 'push';
const DO_NOTHING = 'nope';

const Router = (function () {
  function Router(routes, history) {
    if (routes === undefined) routes = [];
    const onTransition =
      arguments.length <= 2 || arguments[2] === undefined
        ? function transitionFinished() {}
        : arguments[2];

    _classCallCheck(this, Router);

    _invariant2.default(
      Array.isArray(routes),
      `Routes should be an array, ${typeof routes} given.`
    );
    _invariant2.default(
      typeof onTransition === 'function',
      `Router onTransition callback should be a function, ${typeof onTransition} given.`
    );

    this.routes = instantiateRoutes(routes);

    // enable queries means that query parameters can be used directly as objects
    this.history = history;

    this.onTransition = onTransition || function transitionFinished() {};

    this.listeners = {
      changeStart: [],
      changeSuccess: [],
      changeFail: [],
      notFound: []
    };

    this.handlerWrappers = {
      onEnter: function onEnter(_onEnter) {
        return _onEnter();
      },
      onLeave: function onLeave(_onLeave) {
        return _onLeave();
      }
    };

    this._currentRoute = null;
  }

  Router.prototype.listen = function listen() {
    // listen to popState event
    this.history.listen(this._handleChange.bind(this));
  };

  Router.prototype._handleChange = function _handleChange(location) {
    if (location.action === 'POP') {
      // on handle pop state (we are moving in history)
      const path = location.pathname;
      const query = _utilsUrlUtils.parseQuery(location.search);

      this.run(path, query, location.state ? DO_NOTHING : REPLACE_STATE);
    }
  };

  Router.prototype.currentRoute = function currentRoute() {
    return this._currentRoute;
  };

  Router.prototype._wrapRouteHandler = function _wrapRouteHandler(
    name,
    wrapper
  ) {
    _invariant2.default(
      typeof wrapper === 'function',
      `${name} handler wrapper should be a function, ${typeof wrapper} given.`
    );

    this.handlerWrappers[name] = wrapper;
  };

  Router.prototype._callEventListeners = function _callEventListeners(name) {
    for (
      var _len = arguments.length,
        args = Array(_len > 1 ? _len - 1 : 0),
        _key = 1;
      _key < _len;
      _key++
    ) {
      args[_key - 1] = arguments[_key];
    }

    this.listeners[name].forEach(function (listener) {
      return listener.apply(undefined, args);
    });
  };

  Router.prototype._registerEventListener = function _registerEventListener(
    name,
    listener
  ) {
    _invariant2.default(
      typeof listener === 'function',
      `${name} event listener should be function, ${typeof listener} given.`
    );

    const listeners = this.listeners[name];

    listeners.push(listener);

    return function unsubscribe() {
      const index = listeners.indexOf(listener);
      listeners.splice(index);
    };
  };

  Router.prototype._rejectTransition = function _rejectTransition(reason) {
    const _this = this;

    const err = new Error(reason);

    return function (parentErr) {
      const e = parentErr || err;
      _this._callEventListeners('changeFail', e, _this._currentRoute, _this);
      _this.onTransition(e);

      throw err;
    };
  };

  /**
   * Finishes run route resolving
   *
   * @param {Object} resolvedRoute
   * @param {String} path
   * @param {Object} query
   * @param {String} action
   * @returns {Object}
   * @private
   */

  Router.prototype._finishRun = function _finishRun(
    resolvedRoute,
    path,
    query,
    action
  ) {
    this._currentRoute = resolvedRoute;
    this._callEventListeners('changeSuccess', resolvedRoute);

    /* eslint-disable default-case */
    switch (action) {
      case PUSH_STATE:
        this.history.push({
          pathname: _utilsUrlUtils.createHref(path, query),
          state: resolvedRoute
        });
        break;
      case REPLACE_STATE:
        this.history.replace({
          pathname: _utilsUrlUtils.createHref(path, query),
          state: resolvedRoute
        });
        break;
    }
    /* eslint-enable default-case */

    this.onTransition(null, resolvedRoute);

    return resolvedRoute;
  };

  Router.prototype.addChangeStartListener = function addChangeStartListener(
    listener
  ) {
    return this._registerEventListener('changeStart', listener);
  };

  Router.prototype.addChangeSuccessListener = function addChangeSuccessListener(
    listener
  ) {
    return this._registerEventListener('changeSuccess', listener);
  };

  Router.prototype.addChangeFailListener = function addChangeFailListener(
    listener
  ) {
    return this._registerEventListener('changeFail', listener);
  };

  Router.prototype.addNotFoundListener = function addNotFoundListener(
    listener
  ) {
    return this._registerEventListener('notFound', listener);
  };

  /**
   * Wraps route onEnter handler
   *
   * @param {Function} handler
   */

  Router.prototype.wrapOnEnterHandler = function wrapOnEnterHandler(handler) {
    this._wrapRouteHandler('onEnter', handler);
  };

  /**
   * Wraps route onLeave handler
   *
   * @param {Function} handler
   */

  Router.prototype.wrapOnLeaveHandler = function wrapOnLeaveHandler(handler) {
    this._wrapRouteHandler('onLeave', handler);
  };

  /**
   * Starts router transition
   *
   * @param {String} path
   * @param {Object} query
   * @param {String} action
   * @returns {Promise}
   */

  Router.prototype.run = function run(path) {
    const _this2 = this;

    const query =
      arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
    const action =
      arguments.length <= 2 || arguments[2] === undefined
        ? PUSH_STATE
        : arguments[2];

    const runResolvedRoute = function runResolvedRoute(resolvedRoute) {
      const currentRoute = _this2._currentRoute;
      _this2._callEventListeners(
        'changeStart',
        currentRoute,
        resolvedRoute,
        _this2
      );

      const { handlerWrappers } = _this2;

      // call on leave in order (so we can cancel transition)
      return _utilsRouteUtils
        .runRouteHandlers(
          'onLeave',
          currentRoute,
          handlerWrappers,
          resolvedRoute,
          _this2
        )
        .then(function () {
          return _utilsRouteUtils
            .runRouteHandlers(
              'onEnter',
              resolvedRoute,
              handlerWrappers,
              currentRoute,
              resolvedRoute,
              _this2
            )
            .then(function () {
              return _utilsRouteUtils
                .resolveComponents(resolvedRoute.components)
                .then(function (components) {
                  return _this2._finishRun(
                    _extends({}, resolvedRoute, { components }),
                    path,
                    query,
                    action
                  );
                }, _this2._rejectTransition(
                  'Route components cannot be resolved'
                ));
            }, _this2._rejectTransition(
              'Route onEnter handlers are rejected.'
            ));
        }, _this2._rejectTransition(
          'Current route onLeave handlers are rejected.'
        ));
    };

    const notFound = function notFound() {
      const err = new _errors.RouteNotFoundError('Route not found');
      _this2._callEventListeners('notFound', path, query);
      _this2.onTransition(err);

      throw err;
    };

    return _utilsRouterUtils
      .resolveWithFirstMatched(this.routes, path, query)
      .then(runResolvedRoute, notFound);
  };

  return Router;
})();

exports.default = Router;
module.exports = exports.default;
