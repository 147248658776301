// Vendor
import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import { Modal } from './../../../../../../../components';
import { EditSectionForm } from './../../../../../../../forms/admin';

// Actions
import { setSectionData } from './../../../../../../../actions/user';

/**
 * Edit section modal
 * @class
 */
class EditSectionModal extends Component {
    /**
     * Handle submit
     * @param data
     */
    handleSubmit(data) {
        const { userId, courseId, sectionId, setSectionData } = this.props;
        const { removeModal } = this.context;

        setSectionData(userId, courseId, sectionId, data.value).then(
            () => {
                removeModal();
            }
        );
    }

    /**
     * Render
     * @returns {XML}
     */
    render() {
        const { removeModal } = this.context;
        const { sectionId, heading } = this.props;

        return (
            <Modal
                heading={heading}
                onClose={removeModal}
            >
                <p style={{ marginTop: '50px' }}>
                    <FormattedMessage id="SECTION_MODAL_INFO" values={{ max: sectionId === 1 ? 60 : 20 }} />
                </p>
                <EditSectionForm sectionId={sectionId} onSubmit={this.handleSubmit.bind(this)} />
            </Modal>
        );
    }
}

// Prop types
EditSectionModal.propTypes = {
    heading: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    courseId: PropTypes.number.isRequired,
    sectionId: PropTypes.number.isRequired,
    setSectionData: PropTypes.func.isRequired
};

// Context types
EditSectionModal.contextTypes = {
    removeModal: PropTypes.func.isRequired
};

// Connect
export default connect(
    undefined,
    dispatch => (
        bindActionCreators({ setSectionData }, dispatch)
    )
)(EditSectionModal);
