import React, { PropTypes } from 'react';
import Helmet from 'react-helmet';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { UserInfoTable, ProgressBlock } from './../Main/components';

import { getUserData } from './../../../helpers';

const Compare = (props) => {
    const { user } = props;
    let value = 0;
    let max = 0;

    if (user && user.get('graph')) {
        user.get('graph').map((item) => {
            value += item.get('value');
            max += item.get('maximum');

            return true;
        });
    }

    return (
        <div>
            <Helmet title={`${process.env.REACT_APP_TITLE} - Porovnanie`} />

            <UserInfoTable />
            <ProgressBlock />

            <div className={classNames('info-table__progress-info', 'mobile')}>
                <span className="large">
                    <FormattedMessage id="TOTAL_SCORE" />
                </span>
                <span className="main">
                    {value} b
                </span>
                <span className="small">
                    z {max} b
                </span>
            </div>

            <div className="compare">
                <div className="compare__heading">
                    <FormattedMessage id="ALL_USERS" />
                </div>
                <div className="compare__body">
                    <div className="table">
                        {props.users.map((myUser, index) => {
                            let total = 0;
                            myUser.get('graph').map((item) => (
                                total += item.get('value')
                            ));

                            return (
                                <div key={index} className="tr">
                                    <div className="td">
                                        <strong>
                                            {getUserData(myUser).name}
                                        </strong>
                                        <br />
                                        {getUserData(myUser).region}
                                    </div>
                                    <div className="td">
                                        <ProgressBlock
                                            table
                                            graph={myUser.get('graph')}
                                            courses={myUser.get('courses')}
                                        />
                                    </div>
                                    <div className="td">
                                        <FormattedMessage id="TOTAL_SCORE" /><br />
                                        <span className="progress-info">{total} b</span>
                                    </div>

                                    <div className="mobile-progress">
                                        <ProgressBlock
                                            table
                                            graph={myUser.get('graph')}
                                            courses={myUser.get('courses')}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

Compare.propTypes = {
    user: PropTypes.object,
    users: PropTypes.array.isRequired
};

export default connect(
    state => ({
        user: state.users.get('user').get('role') === 'user' ? state.users.get('user') : state.users.getIn(['users', Number(state.router.route.vars.id)]), // eslint-disable-line
        users: state.users.get('users').toArray()
    })
)(Compare);
