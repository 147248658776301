import React, { Component, PropTypes } from 'react';
import classNames from 'classnames';
import { actions } from '../../../../routex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import { Container, Header, Logo } from './../../components';
import EditModal from './../User/Main/components/UserInfoTable/EditModal';
import { UploadModal } from './../Admin/components';

import { logout } from './../../actions/auth';

import Participants from './Participants';
import Themes from './Themes';
import Quiz from './Quiz';
import QuizDetail from './QuizDetail';
import Helmet from "react-helmet";

class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false
        };
    }

    componentDidMount() {
        const { loggedUser } = this.props;

        if (loggedUser.get('role') === 'lector' || loggedUser.get('role') === 'superior') {
            if (!loggedUser.get('intro_passed')) {
                setTimeout(() => {
                    this.showEditModal.bind(this)();
                }, 100);
            }
        }
    }

    showEditModal() {
        const { addModal, removeModal } = this.context;

        addModal(<EditModal onClose={removeModal} />);
    }

    goTo(path) {
        const { transitionTo } = this.props;
        transitionTo(path);
        this.setState({ active: false });
    }

    showUploadModal = () => {
        const { addModal, removeModal } = this.context;

        addModal(<UploadModal onClose={removeModal} />);
    };

    render() {
        const { active } = this.state;
        const { children, role, logout, logoutLoading, usersLoading, coursesLoading, questionsLoading } = this.props;

        const isLoading = usersLoading || coursesLoading || questionsLoading;

        return (
            <div style={{ marginBottom: '2em' }}>
                <Header
                    loggedIn
                    left={<Logo admin />}
                    right={
                        <span>
                            <button
                                className={classNames('hamburger', active && 'active')}
                                onClick={() => { this.setState({ active: !active }); }}
                            />

                            <span className={classNames('header-buttons', active && 'active')}>
                                {isLoading &&
                                    <span className="link">
                                        <FormattedMessage id="LOADING" />
                                    </span>
                                }
                                <span className="link" onClick={this.goTo.bind(this, '/admin/participants')}>
                                    <FormattedMessage id="PARTICIPANTS" />
                                </span>
                                {role === 'lector' &&
                                    <span className="link" onClick={this.goTo.bind(this, '/admin/themes')}>
                                        <FormattedMessage id="THEMES" />
                                    </span>
                                }
                                {role === 'lector' &&
                                    <span className="link" onClick={this.goTo.bind(this, '/admin/quiz')}>
                                        <FormattedMessage id="QUIZ" />
                                    </span>
                                }
                                {role === 'lector' &&
                                    <span className="link" onClick={this.showUploadModal}>
                                        <FormattedMessage id="UPLOAD_FILES" />
                                    </span>
                                }
                                <span className="id">
                                    {role}
                                </span>
                                <button
                                    onClick={() => { logout(); }}
                                    disabled={logoutLoading}
                                    className="logout-button"
                                >
                                    <FormattedMessage id={'LOGOUT'} />
                                </button>
                            </span>
                        </span>
                    }
                />

                <Container>
                    {children}
                </Container>

                <Helmet title={`${process.env.REACT_APP_TITLE} - ${role === 'lector' ? 'Lector zóna' : 'Admin zóna'}`} />
            </div>
        );
    }
}

Admin.contextTypes = {
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired
};

Admin.propTypes = {
    children: PropTypes.any,
    logout: PropTypes.func.isRequired,
    logoutLoading: PropTypes.bool,
    role: PropTypes.string,
    transitionTo: PropTypes.func.isRequired,
    usersLoading: PropTypes.bool,
    coursesLoading: PropTypes.bool,
    questionsLoading: PropTypes.bool,
    loggedUser: PropTypes.object
};

Admin.Participants = Participants;
Admin.Themes = Themes;
Admin.Quiz = Quiz;
Admin.QuizDetail = QuizDetail;

export default connect(
    (state) => ({
        role: state.users.get('user').get('role'),
        logoutLoading: state.auth.get('logoutLoading'),
        usersLoading: state.users.get('usersLoading'),
        coursesLoading: state.users.get('adminCoursesLoading'),
        questionsLoading: state.users.get('questionsLoading'),
        loggedUser: state.users.get('user')
    }),
    (dispatch) => {
        const transitionTo = actions.transitionTo;
        return bindActionCreators({ logout, transitionTo }, dispatch);
    }
)(Admin);
