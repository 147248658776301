import React, { PropTypes } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { AdminTable, ThemesTable } from "./../components";
import { Button } from "../../../components";
import { createCourse, getCourses } from "../../../actions/user";
import EditCourseModal from "../components/ThemesTable/EditCourseModal";
import { isRba } from "../../../helpers";

const Themes = (props, context) => {
  const createCourse = () => {
    const { addModal } = context;
    addModal(<EditCourseModal />);
  };

  return (
    <div>
      <AdminTable
        heading="THEME_LIST"
        buttonGroup={
          <span>
            <Button
              small
              onClick={createCourse}
              disabled={
                isRba()
                  ? props.courses.filter((course) => course.get("active"))
                      .size > 4
                  : props.courses.filter((course) => course.get("active"))
                      .size > 5
              }
            >
              <img
                src={`/${process.env.REACT_APP_TYPE}/assets/icons/add.png`}
                alt="add"
              />
              <FormattedMessage id="ADD_THEME" />
            </Button>
          </span>
        }
      >
        <ThemesTable />
      </AdminTable>
    </div>
  );
};

Themes.contextTypes = {
  addModal: PropTypes.func.isRequired,
};

Themes.propTypes = {
  courses: PropTypes.object.isRequired,
  createCourse: PropTypes.func.isRequired,
  getCourses: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    createCourseLoading: state.users.get("createCourseLoading"),
    courses: state.users.get("adminCourses"),
  }),
  (dispatch) => bindActionCreators({ createCourse, getCourses }, dispatch)
)(Themes);
