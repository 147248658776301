// Vendor
import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// Components
import { Modal } from './../../../../../components';
import { EditUser } from './../../../../../forms/user';

// Actions
import { editUser } from './../../../../../actions/user';

/**
 * Edit modal
 * @class
 */
class EditModal extends Component {
    /**
     * Edit user
     * @param data
     */
    editUser(data) {
        const { editUser } = this.props;
        const { removeModal } = this.context;

        editUser(data).then(
            (action) => {
                if (!action.error) {
                    removeModal();
                }
            }
        );
    }

    /**
     * Render
     * @returns {XML}
     */
    render() {
        const { onClose, user } = this.props;

        return (
            <Modal onClose={onClose}>
                {user.get('intro_passed')
                    ?
                    <h2><FormattedMessage id="DATA_EDIT" /></h2>
                    :
                    <FormattedHTMLMessage id="FIRST_LOGIN_INFO" />
                }

                <EditUser onSubmit={this.editUser.bind(this)} />
            </Modal>
        );
    }
}

// PropTypes
EditModal.propTypes = {
    editUser: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
};

// Context types
EditModal.contextTypes = {
    removeModal: PropTypes.func.isRequired
};

// Connect
export default connect(
    state => ({
        user: state.users.get('user')
    }),
    dispatch => (
        bindActionCreators({ editUser }, dispatch)
    )
)(EditModal);
