/**
 * Trims slashes from path end
 *
 * @param {string} path
 * @returns {string}
 */

exports.__esModule = true;
exports.trimSlashesFromPathEnd = trimSlashesFromPathEnd;
exports.normalizeSlashes = normalizeSlashes;

function trimSlashesFromPathEnd(path) {
  return path.replace(/(\/)$/, '');
}

/**
 * Normalizes occurrences of multiple slashes in one place to just one slash
 *
 * @param {string} path
 * @returns {string}
 */

function normalizeSlashes(path) {
  return path.replace(/(\/)+\//g, '/');
}
