import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reset } from 'redux-form';
import { Map } from 'immutable';

import { AdminTable } from './../components';
import { EditButton, Modal } from './../../../components';
import { QuestionForm, QuestionEditForm } from './../../../forms/admin';
import { RowBreak } from './../../../views/User/Main/components';


import { createQuestion, updateQuestion, updateAnswer, deleteQuestion, filterQuestions } from './../../../actions/user';

const chars = ['a', 'b', 'c'];

@connect(
    (state) => ({
        id: Number(state.router.route.vars.id),
        questions: state.users.hasIn(['adminCourses', Number(state.router.route.vars.id), 'questions'])
            ? state.users.getIn(['adminCourses', Number(state.router.route.vars.id), 'questions']).toArray()
            : Map(),
        filterValue: state.users.get('questionsFilterValue') || '',
        errors: state.users.get('errors')
    }),
    dispatch => (
        bindActionCreators({
            createQuestion,
            updateQuestion,
            updateAnswer,
            deleteQuestion,
            filterQuestions,
            reset
        }, dispatch)
    )
)
class QuizDetail extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        questions: PropTypes.array.isRequired,
        createQuestion: PropTypes.func.isRequired,
        updateQuestion: PropTypes.func.isRequired,
        updateAnswer: PropTypes.func.isRequired,
        deleteQuestion: PropTypes.func.isRequired,
        filterQuestions: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        filterValue: PropTypes.string,
        errors: PropTypes.object.isRequired
    };

    static contextTypes = {
        addModal: PropTypes.func.isRequired,
        removeModal: PropTypes.func.isRequired
    };

    componentDidMount() {
        const { filterQuestions } = this.props;
        filterQuestions('');
    }

    handleSubmit = (data, questionId) => {
        const { id, questions, createQuestion, updateQuestion, updateAnswer, reset } = this.props;
        const { addModal, removeModal } = this.context;

        if (questionId) {
            const questionAnswers = questions.find((q) => q.get('id') === questionId).get('answers');
            const promises = [];
            questionAnswers.map((a, i) => (
                promises.push(
                    updateAnswer(
                        id,
                        questionId,
                        a.get('id'),
                        { text: data.answers[i], is_correct: a.get('is_correct') }
                    )
                )
            ));

            Promise.all(promises);
            updateQuestion(id, questionId, data).then(
                (action) => {
                    if (!action.error) {
                        removeModal();
                        reset('questionEditForm');
                    }
                }
            );
        } else {
            const result = {
                text: data.text,
                answers: [  // eslint-disable-line
                    { text: data.answers[0], is_correct: true },
                    { text: data.answers[1], is_correct: false },
                    { text: data.answers[2], is_correct: false }
                ]
            };

            createQuestion(id, result).then(
                (action) => {
                    if (!action.error) {
                        removeModal();
                        reset('questionForm');
                        addModal(
                            <Modal
                                heading="INFO"
                                onClose={removeModal}
                            >
                                <p style={{ textAlign: 'center', marginTop: '3em' }}>
                                    <FormattedMessage id="QUESTION_CREATED" />
                                </p>
                            </Modal>
                        );
                    }
                }
            );
        }
    };

    removeQuestion = (questionId) => {
        const { id, deleteQuestion } = this.props;
        const { removeModal } = this.context;

        deleteQuestion(id, questionId).then(
            (action) => {
                if (!action.error) {
                    removeModal();
                }
            }
        );
    };

    showModal = (id) => {
        const { questions } = this.props;
        const { addModal, removeModal } = this.context;

        addModal(
            <Modal
                heading="UPDATE_QUESTION"
                onClose={removeModal}
            >
                <div style={{ margin: '3em 0' }}>
                    <QuestionEditForm
                        modal
                        onSubmit={(data) => this.handleSubmit(data, id)}
                        edit={questions.find(q => q.get('id') === id)}
                    />
                    <button
                        className="remove-button"
                        onClick={() => this.removeQuestion(id)}
                    >
                        <FormattedMessage id="REMOVE" />
                    </button>
                </div>
            </Modal>
        );
    };

    renderAnswer = (q, i) => ((
        <div className="question">
            <div className="left">
                <EditButton onClick={() => this.showModal(q.get('id'))} />
            </div>
            <div className="right">
                <h5>{i + 1}. {q.get('text')}</h5>
                {q.get('answers').map((a, j) => (
                    <span className="answer">
                        <input
                            id={q.get('id')}
                            name={a.get('id')}
                            type="radio"
                            checked={a.get('is_correct')}
                        />
                        <label>
                            <span style={{ display: 'inline-block', width: '15px' }}>
                                {chars[j]}.
                            </span> | <span className="answer__text">{a.get('text')}</span>
                        </label>
                    </span>
                ))}
            </div>
        </div>
    ));

    render() {
        const { id, questions, filterQuestions, filterValue, errors } = this.props;
        const result = filterValue
            ? questions.filter(q => q.get('text').toLowerCase().indexOf(filterValue) > -1)
            : questions;

        return (
            <AdminTable
                heading="QUESTIONS_LIST"
                search={filterQuestions}
                rightSearch
                buttonGroup={<span />}
            >
                <QuestionForm onSubmit={(data) => this.handleSubmit(data, null)} />

                <div className="error">
                    {errors.hasIn(['courses', 'questions', id]) && errors.getIn(['courses', 'questions', id]) === 10 &&
                        <FormattedMessage id="LOCKED_COURSE" />
                    }
                </div>

                <RowBreak>
                    <FormattedMessage id="QUESTIONS_LIST" />
                </RowBreak>

                <div>
                    {result.sort((a, b) => {
                        if (a.get('id') > b.get('id')) {
                            return -1;
                        }
                        if (a.get('id') < b.get('id')) {
                            return 1;
                        }
                        return 0;
                    }).map((q, i) => this.renderAnswer(q, i))}
                </div>
            </AdminTable>
        );
    }
}

export default QuizDetail