// Vendor
import React, { PropTypes } from 'react';
import { Link } from '../../../../routex/lib/react';

const Logo = (props) => ((
    <Link to={props.admin ? '/admin/participants' : '/'}><span className="logo" /></Link>
));

Logo.propTypes = {
    admin: PropTypes.bool
};

export default Logo;
