import { Map } from 'immutable';
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE
} from './../../constants/actionTypes';

const initialState = Map({

});

export default function auth(state = initialState, action) {
    switch (action.type) {
        case LOGIN_REQUEST:
            return state.set('loginLoading', true).set('loginError', false);
        case LOGIN_SUCCESS:
            return state.set('loginLoading', false).set('loginError', false);
        case LOGIN_FAILURE:
            return state.set('loginLoading', false).set('loginError', action.error);
        case LOGOUT_REQUEST:
            return state.set('logoutLoading', true);
        case LOGOUT_SUCCESS:
            return state.set('logoutLoading', false);
        case LOGOUT_FAILURE:
            return state.set('logoutLoading', false);
        case FORGOT_PASSWORD_REQUEST:
            return state.set('forgotPasswordLoading', true);
        case FORGOT_PASSWORD_SUCCESS:
            return state.set('forgotPasswordLoading', false);
        case FORGOT_PASSWORD_FAILURE:
            return state.set('forgotPasswordLoading', false).set('forgotPasswordError', action.error);
        default:
            return state;
    }
}
