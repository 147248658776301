import request from 'superagent-bluebird-promise';
import {
    CREATE_QUESTION_REQUEST,
    CREATE_QUESTION_SUCCESS,
    CREATE_QUESTION_FAILURE
} from '../../constants/actionTypes';

/**
 * Create question action
 *
 * @param{Number} id
 * @param{Object} data
 * @returns {{types: *[], promise: Request }}
 */

export default function createQuestion(id, data) {
    return {
        types: [CREATE_QUESTION_REQUEST, CREATE_QUESTION_SUCCESS, CREATE_QUESTION_FAILURE],
        promise: request
                    .post(`${process.env.REACT_APP_API_URL}/api/v1/course/${id}/question`)
                    .set('Authorization', `Bearer ${sessionStorage.getItem('access_token')}`)
                    .send(data),
        id
    };
}
