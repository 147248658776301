// Vendor
import React, { Component, PropTypes } from 'react';

// Components
import { ModalSystem } from './../../containers';

/**
 * App
 * @param props
 * @returns {XML}
 * @constructor
 */
class App extends Component {
    /**
     * Constructor
     * @param props
     * @param context
     */
    constructor(props, context) {
        super(props, context);
        this.addModal = this.addModal.bind(this);
        this.removeModal = this.removeModal.bind(this);
    }

    /**
     * Get child context
     * @returns {*{}}
     */
    getChildContext() {
        return {
            addModal: this.addModal,
            removeModal: this.removeModal
        };
    }

    /**
     * Add modal action
     * @param modal
     */
    addModal(modal) {
        this.refs.modalSystem.addModal(modal);
    }

    /**
     * Remove modal action
     */
    removeModal() {
        this.refs.modalSystem.removeModal();
    }

    /**
     * Render
     * @returns {XML}
     */
    render() {
        const { children } = this.props;

        return (
            <div>
                {children}
                <ModalSystem ref="modalSystem" />
            </div>
        );
    }
}

// Prop types
App.propTypes = {
    children: PropTypes.any
};

// Context types
App.childContextTypes = {
    addModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired
};

export default App;
