exports.__esModule = true;

const _extends =
  Object.assign ||
  function (target) {
    for (let i = 1; i < arguments.length; i++) {
      const source = arguments[i];
      for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

exports.parseQuery = parseQuery;
exports.stringifyQuery = stringifyQuery;
exports.createHref = createHref;

const _qs = require('qs');

/**
 * Parses query
 *
 * @param {String} search
 * @returns {Object.<String, *>}
 */

function parseQuery(search) {
  if (/^\?/.test(search)) {
    return _qs.parse(search.substring(1));
  }

  return {};
}

/**
 * Stringifies query
 *
 * @param {Object.<String, *>} query
 * @returns {String}
 */

function stringifyQuery() {
  const query =
    arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

  return _qs.stringify(query, { arrayFormat: 'brackets' });
}

/**
 * Creates href
 *
 * @param {String} path
 * @param {Object.<String, *>} query
 * @returns {String}
 */

function createHref(path) {
  const query =
    arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

  // if path contains ? strip it
  const match = path.match(/^([^?]*)(\?.*)?$/);

  let url = `${match[1]}`;
  let queryParams = match[2] ? parseQuery(match[2]) : {};

  // merge with query
  queryParams = _extends({}, queryParams, query);

  // stringify params only if query contains something
  if (Object.keys(queryParams).length) {
    url += `?${stringifyQuery(queryParams)}`;
  }

  return url;
}
