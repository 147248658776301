import { Map, OrderedMap, fromJS } from "immutable";
import moment from "moment";
import {
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  FETCH_COURSE_REQUEST,
  FETCH_COURSE_SUCCESS,
  FETCH_COURSE_FAILURE,
  FETCH_QUIZ_REQUEST,
  FETCH_QUIZ_SUCCESS,
  FETCH_QUIZ_FAILURE,
  EVALUATE_QUIZ_REQUEST,
  EVALUATE_QUIZ_SUCCESS,
  EVALUATE_QUIZ_FAILURE,
  SET_SECTION_DATA_REQUEST,
  SET_SECTION_DATA_SUCCESS,
  SET_SECTION_DATA_FAILURE,
  SET_NOTICE_REQUEST,
  SET_NOTICE_SUCCESS,
  SET_NOTICE_FAILURE,
  GET_COURSES_REQUEST,
  GET_COURSES_SUCCESS,
  GET_COURSES_FAILURE,
  EDIT_COURSE_REQUEST,
  EDIT_COURSE_SUCCESS,
  EDIT_COURSE_FAILURE,
  FETCH_QUESTIONS_REQUEST,
  FETCH_QUESTIONS_SUCCESS,
  FETCH_QUESTIONS_FAILURE,
  CREATE_QUESTION_REQUEST,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_FAILURE,
  UPDATE_QUESTION_REQUEST,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_FAILURE,
  DELETE_QUESTION_REQUEST,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAILURE,
  CREATE_COURSE_REQUEST,
  CREATE_COURSE_SUCCESS,
  CREATE_COURSE_FAILURE,
  ARCHIVE_COURSE_REQUEST,
  ARCHIVE_COURSE_SUCCESS,
  ARCHIVE_COURSE_FAILURE,
  FILTER_USERS,
  FILTER_QUESTIONS,
  LOGOUT_SUCCESS,
} from "./../../constants/actionTypes";
const initialState = Map({
  user: Map(),
  users: Map(),
  userLoading: true,
  courses: OrderedMap(),
  coursesLoading: OrderedMap(),
  coursesError: OrderedMap(),
  quizError: OrderedMap(),
  quizResults: OrderedMap(),
  adminCourses: OrderedMap(),
  errors: Map(),
});

function setUserState(user) {
  const courses = user.courses;

  if (courses) {
    courses.map(
      (course) =>
        (course.started = moment().isAfter(
          moment(course.start_date || course.date)
        )) // eslint-disable-line
    );

    user.courses = courses; // eslint-disable-line
  }

  return user;
}

function setCourseState(course) {
  course.started = moment().isAfter(moment(course.date)); // eslint-disable-line

  return course;
}

export default function users(state = initialState, action) {
  switch (action.type) {
    case CREATE_USER_REQUEST:
      return state.set("createUserLoading", true);
    case CREATE_USER_SUCCESS: {
      const user = setUserState(action.result.body);

      return state
        .set("createUserLoading", false)
        .set("createUserError", false)
        .setIn(["users", user.id], fromJS(user));
    }
    case CREATE_USER_FAILURE:
      return state
        .set("createUserLoading", false)
        .set("createUserError", action.error.body || { error: true });
    case FETCH_USER_REQUEST:
      return state.set("userLoading", true);
    case FETCH_USER_SUCCESS: {
      const user = setUserState(action.result.body);
      if (user.image) {
        user.image = `${user.image}?${Date.now()}`;
      }

      return state
        .set("userLoading", false)
        .set("user", fromJS(user))
        .set("userLoaded", true);
    }
    case FETCH_USER_FAILURE:
      return state.set("userLoading", false).set("error", action.error);

    case FETCH_USERS_REQUEST:
      return state.set("usersLoading", true);
    case FETCH_USERS_SUCCESS: {
      const users = action.result.body;

      return state
        .set("usersLoading", false)
        .setIn(
          ["users"],
          Map(users.map((user) => [user.id, fromJS(setUserState(user))]))
        )
        .set("userLoaded", true);
    }
    case FETCH_USERS_FAILURE:
      return state.set("usersLoading", false).set("error", action.error);

    case EDIT_USER_REQUEST:
      return state.set("editUserLoading", true);
    case EDIT_USER_SUCCESS: {
      const user = action.result.body;

      if (user.role === "user") {
        if (user.image) {
          user.image = `${user.image}?${Date.now()}`;
        }

        return state
          .set("editUserLoading", false)
          .set("user", fromJS(setUserState(user)))
          .setIn(["users", user.id], fromJS(setUserState(user)))
          .set("userLoaded", true);
      }

      return state;
    }
    case EDIT_USER_FAILURE:
      return state
        .set("editUserLoading", false)
        .set("editError", fromJS(action.error.body));

    case FETCH_COURSE_REQUEST: {
      const id = action.id;
      return state.setIn(["coursesLoading", id], true);
    }
    case FETCH_COURSE_SUCCESS: {
      const id = action.id;
      const course = setCourseState(action.result.body);

      return state
        .setIn(["coursesLoading", id], false)
        .setIn(["courses", id], fromJS(course));
    }
    case FETCH_COURSE_FAILURE: {
      const id = action.id;
      return state
        .setIn(["coursesLoading", id], false)
        .setIn(["coursesError", id], action.error);
    }
    case FETCH_QUIZ_REQUEST: {
      const id = action.id;
      return state.setIn(["coursesLoading", "quiz", id], true);
    }
    case FETCH_QUIZ_SUCCESS: {
      const id = action.id;
      const questions = action.result.body;

      return state
        .setIn(["coursesLoading", "quiz", id], false)
        .mergeIn(
          ["courses", id, "quiz"],
          Map(questions.map((question) => [question.id, fromJS(question)]))
        );
    }
    case FETCH_QUIZ_FAILURE: {
      const id = action.id;
      return state
        .setIn(["coursesLoading", "quiz", id], false)
        .setIn(["coursesError", "quiz", id], fromJS(action.error.body));
    }
    case EVALUATE_QUIZ_REQUEST: {
      return state.set("quizEvaluateLoading", true);
    }
    case EVALUATE_QUIZ_SUCCESS: {
      const id = action.id;
      const results = action.results;

      return state
        .set("quizEvaluateLoading", false)
        .setIn(["courses", id, "quiz_generated"], true)
        .setIn(["quizResults", id], fromJS(results));
    }
    case EVALUATE_QUIZ_FAILURE: {
      const id = action.id;
      return state
        .set("quizEvaluateLoading", false)
        .setIn(["quizError", id], fromJS(action.error));
    }

    // ADMIN
    case SET_SECTION_DATA_REQUEST:
      return state.set("setSectionLoading", true);
    case SET_SECTION_DATA_SUCCESS: {
      const userId = action.userId;
      const courseId = action.courseId;

      const course = action.result.body;
      let sum = 0;

      course.graph_sections.map((item) => (sum += item.value));

      let list = state.getIn(["users", userId, "graph"]);

      list = list.update(
        list.findIndex((item) => item.get("course_id") === courseId),
        (item) => item.set("value", sum)
      );

      return state
        .set("setSectionLoading", false)
        .setIn(["courses", courseId], fromJS(setCourseState(course)))
        .setIn(["users", userId, "graph"], list);
    }
    case SET_SECTION_DATA_FAILURE:
      return state
        .set("setSectionLoading", false)
        .set("setSectionError", fromJS(action.error));

    case SET_NOTICE_REQUEST:
      return state.set("editNoticeLoading", true);
    case SET_NOTICE_SUCCESS: {
      const result = action.result.body;
      const courseId = action.courseId;

      return state
        .set("editNoticeLoading", false)
        .setIn(["courses", courseId, "notice"], result.notice);
    }
    case SET_NOTICE_FAILURE:
      return state.set("editNoticeLoading", false);

    case GET_COURSES_REQUEST:
      return state.set("adminCoursesLoading", true);
    case GET_COURSES_SUCCESS: {
      const courses = action.result.body;

      return state
        .set("adminCoursesLoading", false)
        .setIn(
          ["adminCourses"],
          Map(
            courses.map((course) => [course.id, fromJS(setCourseState(course))])
          )
        );
    }
    case GET_COURSES_FAILURE:
      return state.set("adminCoursesLoading", false);

    case EDIT_COURSE_REQUEST:
      return state.set("editCourseLoading", true);
    case EDIT_COURSE_SUCCESS: {
      const courseId = action.courseId;
      const course = action.result.body;

      return state
        .set("editCourseLoading", false)
        .setIn(["editCourseError", courseId], null)
        .mergeIn(["adminCourses", courseId], fromJS(setCourseState(course)));
    }
    case EDIT_COURSE_FAILURE:
      return state
        .set("editCourseLoading", false)
        .setIn(["editCourseError", action.courseId], action.error);

    case FILTER_USERS: {
      const value = action.value.toLowerCase();

      return state.set("filterValue", value);
    }

    case FETCH_QUESTIONS_REQUEST:
      return state.set("questionsLoading", true);
    case FETCH_QUESTIONS_SUCCESS: {
      const id = Number(action.id);
      const questions = action.result.body;

      return state
        .set("questionsLoading", false)
        .setIn(
          ["adminCourses", id, "questions"],
          Map(questions.map((q) => [q.id, fromJS(q)]))
        );
    }
    case FETCH_QUESTIONS_FAILURE:
      return state.set("questionsLoading", false);

    case CREATE_QUESTION_REQUEST:
      return state.set("createQuestionLoading", true);
    case CREATE_QUESTION_SUCCESS: {
      const id = Number(action.id);
      const question = action.result.body;

      return state
        .set("createQuestionLoading", false)
        .setIn(["adminCourses", id, "questions", question.id], fromJS(question))
        .setIn(
          ["adminCourses", id, "question_count"],
          state.getIn(["adminCourses", id, "question_count"]) + 1
        );
    }
    case CREATE_QUESTION_FAILURE: {
      const id = Number(action.id);

      return state
        .set("createQuestionLoading", false)
        .setIn(
          ["errors", "courses", "questions", id],
          action.error.body.error_code
        );
    }
    case UPDATE_QUESTION_REQUEST:
      return state.set("editQuestionLoading", true);
    case UPDATE_QUESTION_SUCCESS: {
      const id = Number(action.id);
      const questionId = Number(action.questionId);

      return state
        .set("editQuestionLoading", false)
        .setIn(
          ["adminCourses", id, "questions", questionId],
          fromJS(action.result.body)
        );
    }
    case UPDATE_QUESTION_FAILURE:
      return state.set("editQuestionLoading", false);

    case DELETE_QUESTION_REQUEST:
      return state.set("deleteQuestionLoading", true);
    case DELETE_QUESTION_SUCCESS: {
      const id = Number(action.id);
      const questionId = Number(action.questionId);

      return state
        .set("deleteQuestionLoading", false)
        .deleteIn(["adminCourses", id, "questions", questionId]);
    }
    case DELETE_QUESTION_FAILURE:
      return state.set("deleteQuestionLoading", false);

    case CREATE_COURSE_REQUEST:
      return state.set("createCourseLoading", true);
    case CREATE_COURSE_SUCCESS: {
      return state.set("createCourseLoading", false);
    }
    case CREATE_COURSE_FAILURE:
      return state
        .set("createCourseLoading", false)
        .setIn(["editCourseError", action.courseId], action.error);

    case ARCHIVE_COURSE_REQUEST:
      return state.set("archiveCourseLoading", true);
    case ARCHIVE_COURSE_SUCCESS: {
      return state.set("archiveCourseLoading", false);
    }
    case ARCHIVE_COURSE_FAILURE:
      return state.set("archiveCourseLoading", false);

    case FILTER_QUESTIONS: {
      const value = action.value.toLowerCase();

      return state.set("questionsFilterValue", value);
    }

    case LOGOUT_SUCCESS: {
      return (state = initialState); // eslint-disable-line
    }
    default:
      return state;
  }
}
