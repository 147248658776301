// Vendor
import React, { PropTypes } from 'react';

/**
 * Button group
 * @param props
 * @constructor
 */
const ButtonGroup = (props) => ((
    <div className="button-group">
        {props.children}
    </div>
));

ButtonGroup.propTypes = {
    children: PropTypes.any
};

export default ButtonGroup;
