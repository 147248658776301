exports.__esModule = true;
const ROUTE_CHANGE_START = '@@ROUTEX/ROUTE_CHANGE_START';
exports.ROUTE_CHANGE_START = ROUTE_CHANGE_START;
const ROUTE_CHANGE_SUCCESS = '@@ROUTEX/ROUTE_CHANGE_SUCCESS';
exports.ROUTE_CHANGE_SUCCESS = ROUTE_CHANGE_SUCCESS;
const ROUTE_CHANGE_FAIL = '@@ROUTEX/ROUTE_CHANGE_FAIL';
exports.ROUTE_CHANGE_FAIL = ROUTE_CHANGE_FAIL;
const ROUTE_NOT_FOUND = '@@ROUTEX/ROUTE_NOT_FOUND';
exports.ROUTE_NOT_FOUND = ROUTE_NOT_FOUND;
const TRANSITION_TO = '@@ROUTEX/TRANSITION_TO';
exports.TRANSITION_TO = TRANSITION_TO;
