// Vendor
import React, { PropTypes, Component } from 'react';
import Helmet from 'react-helmet';
import { actions } from '../../../../../routex';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from '../../../../../routex/lib/react';

// Component
import { ForgotPasswordForm } from './../../../forms/auth';

// Actions
import { forgotPassword } from './../../../actions/auth';

/**
 * Login
 * @class
 */
class ForgotPassword extends Component {
    /**
     * Handle submit
     * @param data
     */
    handleSubmit(data) {
        const { forgotPassword, transitionTo } = this.props;

        forgotPassword(data.username).then(
            (action) => {
                if (!action.error) {
                    transitionTo('/auth/login');
                }
            }
        );
    }

    /**
     * Render
     * @returns {XML}
     */
    render() {
        return (
            <div>
                <Helmet title={`${process.env.REACT_APP_TITLE} - Forgot password`} />

                <div className="auth-form">
                    <ForgotPasswordForm
                        isLoading={this.props.forgotPasswordLoading}
                        onSubmit={this.handleSubmit.bind(this)}
                    />

                    <div className="auth-form__link">
                        <Link to="/auth/login"><FormattedMessage id="LOGIN" /></Link>
                    </div>
                </div>

                <div className="contact-block">
                    <p><FormattedMessage id="CONTACT_US_INFO" /></p>
                    <a href="mailto:pba_bba@tatrabanka.sk">
                        <button className="contact-block__button">
                            <FormattedMessage id="CONTACT_US" />
                        </button>
                    </a>
                </div>
            </div>
        );
    }
}

// Prop Types
ForgotPassword.propTypes = {
    forgotPasswordLoading: PropTypes.bool,
    forgotPassword: PropTypes.func.isRequired,
    transitionTo: PropTypes.func.isRequired
};

// Connect
export default connect(
    (state) => ({
        forgotPasswordLoading: state.auth.get('forgotPasswordLoading')
    }),
    (dispatch) => {
        const transitionTo = actions.transitionTo;
        return bindActionCreators({ forgotPassword, transitionTo }, dispatch);
    }
)(ForgotPassword);
