// Vendor
import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import { Modal } from './../../../../../../../components';
import { EditNoticeForm } from './../../../../../../../forms/admin';

// Actions
import { setNotice } from './../../../../../../../actions/user';

/**
 * Edit notice modal
 * @class
 */
class NoticeModal extends Component {
    /**
     * Handle submit
     * @param data
     */
    handleSubmit(data) {
        const { userId, courseId, setNotice } = this.props;
        const { removeModal } = this.context;

        setNotice(userId, courseId, data.value).then(
            () => {
                removeModal();
            }
        );
    }

    /**
     * Render
     * @returns {XML}
     */
    render() {
        const { removeModal } = this.context;
        const { courseId } = this.props;

        return (
            <Modal
                heading="EVALUATION"
                onClose={removeModal}
            >
                <EditNoticeForm courseId={courseId} onSubmit={this.handleSubmit.bind(this)} />
            </Modal>
        );
    }
}

// Prop types
NoticeModal.propTypes = {
    userId: PropTypes.number.isRequired,
    courseId: PropTypes.number.isRequired,
    setNotice: PropTypes.func.isRequired
};

// Context types
NoticeModal.contextTypes = {
    removeModal: PropTypes.func.isRequired
};

// Connect
export default connect(
    undefined,
    dispatch => (
        bindActionCreators({ setNotice }, dispatch)
    )
)(NoticeModal);
