/*eslint-disable*/
import React, { Component, PropTypes } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import Immutable from "immutable";

import { isBba, isRba } from "../../helpers";

const getActivePies = (selected) => {
  switch (selected) {
    case 0:
    case 1:
      return [0, 1];
    case 2:
    case 3:
      return [2, 3];
    case 4:
    case 5:
      return [4, 5];
    case 6:
    case 7:
      return [6, 7];
    default:
      return [];
  }
};

class Graph extends Component {
  constructor(props) {
    super(props);

    const graphData = [];

    if (this.props.graphData) {
      graphData.push(
        this.props.graphData.find((item) => item.get("section_id") === 2).toJS()
      );
      if (!isBba()) {
        graphData.push(
          this.props.graphData
            .find((item) => item.get("section_id") === 4)
            .toJS()
        );
      }
      graphData.push(
        this.props.graphData.find((item) => item.get("section_id") === 3).toJS()
      );
      graphData.push(
        this.props.graphData.find((item) => item.get("section_id") === 1).toJS()
      );
    }

    let normalizedData = [];
    let sum = 0;

    graphData.map((item) => {
      normalizedData.push({ value: item.value, description: item.description });
      normalizedData.push({
        value: item.maximum - item.value,
        description: item.description,
      });
      sum += item.value;

      return true;
    });

    this.state = {
      active: false,
      sum,
      data: normalizedData,
      selected: false,
      componentKey: Math.random(),
    };
  }

  componentWillUpdate(nextProps) {
    if (!Immutable.is(nextProps.graphData, this.props.graphData)) {
      const graphData = [];

      graphData.push(
        nextProps.graphData.find((item) => item.get("section_id") === 2).toJS()
      );
      if (!isBba()) {
        graphData.push(
          nextProps.graphData
            .find((item) => item.get("section_id") === 4)
            .toJS()
        );
      }
      graphData.push(
        nextProps.graphData.find((item) => item.get("section_id") === 3).toJS()
      );
      graphData.push(
        nextProps.graphData.find((item) => item.get("section_id") === 1).toJS()
      );

      let normalizedData = [];
      let sum = 0;

      graphData.map((item) => {
        normalizedData.push({
          value: item.value,
          description: item.description,
        });
        normalizedData.push({
          value: item.maximum - item.value,
          description: item.description,
        });
        sum += item.value;

        return true;
      });

      this.setState({
        data: normalizedData,
        sum,
        componentKey: Math.random(),
      });
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ active: true });
    }, 400);
  }

  setActivePie(sliceIndex) {
    this.setState({
      selected: sliceIndex === this.state.selected ? null : sliceIndex,
    });
  }

  render() {
    const colors = isBba()
      ? ["#de629a", "#de629a", "#86a83e", "#86a83e", "#76b8c8", "#76b8c8"]
      : isRba()
      ? [
          "#f16fab",
          "#de629a",
          "#f78e57",
          "#f78e57",
          "#8dc73f",
          "#8dc73f",
          "#6dcff6",
          "#d3f1fc",
        ]
      : [
          "#de629a",
          "#de629a",
          "#d29747",
          "#d29747",
          "#86a83e",
          "#86a83e",
          "#76b8c8",
          "#76b8c8",
        ];
    const { sum, active, data, selected, componentKey } = this.state;

    const help = getActivePies(selected);

    const getValue = (selected) => {
      switch (selected) {
        case 0:
        case 1:
          return data[0].value;
        case 2:
        case 3:
          return data[2].value;
        case 4:
        case 5:
          return data[4].value;
        case 6:
        case 7:
          return data[6].value;
        default:
          return [];
      }
    };

    return (
      <div className="graph" key={componentKey}>
        <span className={classNames("graph__info", active && "active")}>
          {!this.props.disabled && (
            <span>
              {isBba() ? (
                <span className="info">
                  {help.toString() === "0,1" && (
                    <FormattedMessage id="PARTICIPATION" />
                  )}
                  {help.toString() === "2,3" && (
                    <FormattedMessage id="ACTIVITY" />
                  )}
                  {help.toString() === "4,5" && (
                    <FormattedMessage id="FULFILLED_ASSIGNMENT" />
                  )}
                  {!selected && selected !== 0 && (
                    <FormattedMessage id="TOTAL_SCORE" />
                  )}
                </span>
              ) : (
                <span className="info">
                  {help.toString() === "0,1" && (
                    <FormattedMessage id="PARTICIPATION" />
                  )}
                  {help.toString() === "2,3" && <FormattedMessage id="QUIZ" />}
                  {help.toString() === "4,5" && (
                    <FormattedMessage id="ACTIVITY" />
                  )}
                  {help.toString() === "6,7" && (
                    <FormattedMessage id="FULFILLED_ASSIGNMENT" />
                  )}
                  {!selected && selected !== 0 && (
                    <FormattedMessage id="TOTAL_SCORE" />
                  )}
                </span>
              )}
              <span className="graph_progress">
                {selected || selected === 0
                  ? getValue(selected) || 0
                  : isRba()
                  ? Math.min(100, sum)
                  : sum}{" "}
                b
              </span>
            </span>
          )}
          {this.props.disabled && (
            <span className="disabledInfo">
              <FormattedMessage id="NOT_STARTED_THEME" />
            </span>
          )}
        </span>
        <Pie
          data={data}
          radius={130}
          hole={85}
          colors={colors}
          labels={true}
          percent={true}
          strokeWidth={2}
          stroke={isRba() ? "#ffffff" : "#1b1b1b"}
          disabled={this.props.disabled}
          setActivePie={this.setActivePie.bind(this)}
          selected={selected}
        />
      </div>
    );
  }
}

function getAnglePoint(startAngle, endAngle, radius, x, y) {
  var x1, y1, x2, y2;

  x1 = x + radius * Math.cos((Math.PI * startAngle) / 180);
  y1 = y + radius * Math.sin((Math.PI * startAngle) / 180);
  x2 = x + radius * Math.cos((Math.PI * endAngle) / 180);
  y2 = y + radius * Math.sin((Math.PI * endAngle) / 180);

  return { x1, y1, x2, y2 };
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

class Pie extends Component {
  render() {
    var colors = this.props.colors,
      colorsLength = colors.length,
      labels = this.props.labels,
      hole = this.props.hole,
      radius = this.props.radius,
      diameter = radius * 2,
      self = this,
      sum,
      startAngle,
      d = null;

    sum = this.props.data.reduce(function (carry, current) {
      return carry + current.value;
    }, 0);
    startAngle = 0;

    return (
      <svg
        width={diameter}
        height={diameter}
        viewBox={"0 0 " + diameter + " " + diameter}
        style={{ overflow: "visible" }}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        className="svg"
      >
        {this.props.data.map(function (slicer, sliceIndex) {
          var slice = slicer.value;
          var angle, nextAngle, percent;

          nextAngle = startAngle;
          angle = (slice / sum) * 360;
          percent = (slice / sum) * 100;
          startAngle += angle;

          return (
            <Slice
              key={sliceIndex}
              index={sliceIndex}
              selected={self.props.selected}
              onClick={() => {
                self.props.setActivePie(sliceIndex);
              }}
              value={slice}
              disabled={self.props.disabled}
              percent={self.props.percent}
              percentValue={percent.toFixed(1)}
              startAngle={nextAngle}
              angle={angle}
              radius={radius}
              hole={radius - hole}
              trueHole={hole}
              showLabel={labels}
              fill={
                self.props.disabled
                  ? "#838383"
                  : colors[sliceIndex % colorsLength]
              }
              stroke={self.props.stroke}
              strokeWidth={self.props.strokeWidth}
              description={slicer.description}
            />
          );
        })}
      </svg>
    );
  }
}

class Slice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      path: "",
      x: 0,
      y: 0,
    };
  }

  componentWillUpdate(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ path: "", x: 0, y: 0 });
      this.animate();
    }
  }

  componentDidMount() {
    this.animate();
  }

  animate() {
    this.draw(0);
  }

  draw(s) {
    var p = this.props,
      path = [],
      a,
      b,
      c,
      self = this,
      step;

    step = p.angle / (37.5 / 2);

    if (s + step > p.angle) {
      s = p.angle;
    }

    // Get angle points
    a = getAnglePoint(
      p.startAngle,
      p.startAngle + s,
      p.radius,
      p.radius,
      p.radius
    );
    b = getAnglePoint(
      p.startAngle,
      p.startAngle + s,
      p.radius - p.hole,
      p.radius,
      p.radius
    );

    path.push("M" + a.x1 + "," + a.y1);
    path.push(
      "A" +
        p.radius +
        "," +
        p.radius +
        " 0 " +
        (s > 180 ? 1 : 0) +
        ",1 " +
        a.x2 +
        "," +
        a.y2
    );
    path.push("L" + b.x2 + "," + b.y2);
    path.push(
      "A" +
        (p.radius - p.hole) +
        "," +
        (p.radius - p.hole) +
        " 0 " +
        (s > 180 ? 1 : 0) +
        ",0 " +
        b.x1 +
        "," +
        b.y1
    );

    // Close
    path.push("Z");

    this.setState({ path: path.join(" ") });

    if (s < p.angle) {
      setTimeout(function () {
        self.draw(s + step);
      }, 16);
    } else if (p.showLabel) {
      c = getAnglePoint(
        p.startAngle,
        p.startAngle + p.angle / 2,
        p.radius / 2 + p.trueHole / 2,
        p.radius,
        p.radius
      );

      this.setState({
        x: c.x2,
        y: c.y2,
      });
    }
  }

  render() {
    const { index, selected, onClick, disabled } = this.props;
    const activePies = getActivePies(selected);

    return (
      <g
        overflow="hidden"
        className={classNames(
          "slice",
          activePies.indexOf(index) > -1 &&
            `active-${activePies[activePies.indexOf(index)]}`
        )}
        onClick={!disabled ? onClick : null}
      >
        <path
          d={this.state.path}
          fill={this.props.fill}
          stroke={this.props.stroke}
          strokeWidth={this.props.strokeWidth ? this.props.strokeWidth : 3}
        />
      </g>
    );
  }
}

Graph.propTypes = {
  graphData: PropTypes.object,
};

export default Graph;
