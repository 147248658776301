exports.__esModule = true;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

const _react = require('react');

const _react2 = _interopRequireDefault(_react);

const _reactRedux = require('react-redux');

const _Link = require('./Link');

const _Link2 = _interopRequireDefault(_Link);

const _View = require('./View');

const _View2 = _interopRequireDefault(_View);

exports.default = {
  Link: _Link2.default(_react2.default, _reactRedux.connect),
  View: _View2.default(_react2.default, _reactRedux.connect)
};
module.exports = exports.default;
