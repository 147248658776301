// Vendor
import React, { PropTypes } from 'react';

// Components
import { Container, Header, Logo } from './../../components';

// Subviews
import Login from './Login';
import ForgotPassword from './ForgotPassword';

const Auth = (props) => ((
    <div>
        <Header
            left={<Logo />}
        />

        <Container>
            {props.children}
        </Container>
    </div>
));

Auth.Login = Login;
Auth.ForgotPassword = ForgotPassword;

Auth.propTypes = {
    children: PropTypes.any
};

export default Auth;
