import {
    FILTER_QUESTIONS
} from './../../constants/actionTypes';

export default function filterQuestions(value) {
    return {
        type: FILTER_QUESTIONS,
        value
    };
}
