/**
 * @author Ján Kertis <kertis.jan.15@gmail.com>
 * @copyright Ján Kertis, 2016
 */

// Vendor
import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

const form = 'formQuiz';
const enums = ['A', 'B', 'C', 'D', 'E', 'F'];

/**
 * Quiz form
 * @class
 */
let QuizForm = (props) => {
    const { handleSubmit, questions, formValues } = props;
    if (!formValues || !formValues.values) {
        formValues.values = {};
    }

    return (
        <form autoComplete="off" className="form" onSubmit={handleSubmit}>
            {questions.toArray().map((item, i) => (
                <div key={i} className="question">
                    <h5>{item.get('text')}</h5>
                    {item.get('answers').map((answer, j) => {
                        const actual = Number(formValues.values[item.get('id')]);
                        const answerId = Number(answer.get('id'));

                        return (
                            <span className="answer" key={j}>
                                <Field
                                    id={answer.get('id')}
                                    name={item.get('id')}
                                    component="input"
                                    type="radio"
                                    value={answer.get('id')}
                                    checked={actual === answerId}
                                />
                                <label htmlFor={`${answer.get('id')}`}>
                                    <span style={{ display: 'inline-block', width: '15px' }}>
                                        {enums[j]}
                                    </span> | <span className="answer__text">{answer.get('text')}</span>
                                </label>
                            </span>
                        );
                    })}
                </div>
            ))}
        </form>
    );
};

// Prop types
QuizForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    questions: PropTypes.object.isRequired,
    formValues: PropTypes.object
};

// Connect
QuizForm = connect(
    state => ({
        formValues: state.form.formQuiz || {}
    })
)(QuizForm);

// Decorate with redux-form
QuizForm = reduxForm({
    form
})(QuizForm);

export default QuizForm;
