// Components
import { App, Auth, User, Admin } from './views';

// Actions
import { fetchUser, fetchUsers, getCourses, fetchQuestions } from './actions/user';

/**
 * On detail enter handler
 * @param actionCreator
 * @returns {Function}
 */
function onDetailEnterHandler(actionCreator) {
    return (currentRoute, nextRoute, router, dispatch) => {
        const { id } = nextRoute.vars;

        return dispatch(actionCreator(id));
    };
}

/**
 * On enter handler
 * @param actionCreator
 * @param key
 * @returns {Function}
 */
function onEnterHandler(actionCreator, key) {
    return (currentRoute, nextRoute, router, dispatch, getState) => {
        const state = getState();

        if (key === 'user') {
            if (state.users.get('user').size > 0) {
                return Promise.resolve();
            }
        }

        if (key === 'users') {
            if (state.users.get('users').size > 0) {
                return Promise.resolve();
            }
        }

        if (key === 'adminCourses') {
            if (state.users.get('adminCourses').size > 1) {
                return Promise.resolve();
            }
        }

        return dispatch(actionCreator());
    };
}

// App routes
const AppRoutes = [
    {
        path: '/',
        component: User.Main
    },
    {
        path: '/compare',
        component: User.Compare,
        onEnter: onEnterHandler(fetchUsers, 'users')
    }
];

const AuthRoutes = [
    {
        path: '/login',
        component: Auth.Login
    },
    {
        path: '/forgot-password',
        component: Auth.ForgotPassword
    }
];

const AdminRoutes = [
    {
        path: '/participants',
        component: Admin.Participants,
        onEnter: onEnterHandler(fetchUsers, 'users')
    },
    {
        path: '/participants/detail/:id',
        component: Admin.Participants.Detail,
        onEnter: onEnterHandler(fetchUsers, 'users')
    },
    {
        path: '/themes',
        component: Admin.Themes,
        onEnter: onEnterHandler(getCourses, 'adminCourses')
    },
    {
        path: '/quiz',
        component: Admin.Quiz,
        onEnter: onEnterHandler(getCourses, 'adminCourses')
    },
    {
        path: '/quiz/:id',
        component: Admin.QuizDetail,
        onEnter: onDetailEnterHandler(fetchQuestions)
    }
];

// Main route
const MainRoutes = [
    {
        path: '/',
        component: User,
        onEnter: onEnterHandler(fetchUser, 'user'),
        children: AppRoutes
    },
    {
        path: '/admin',
        component: Admin,
        onEnter: onEnterHandler(fetchUser, 'user'),
        children: AdminRoutes
    },
    {
        path: '/auth',
        component: Auth,
        children: AuthRoutes
    }
];

export default [
    {
        path: '/',
        component: App,
        children: MainRoutes
    }
];
