import request from "superagent-bluebird-promise";
import {
  CREATE_COURSE_REQUEST,
  CREATE_COURSE_SUCCESS,
  CREATE_COURSE_FAILURE,
} from "../../constants/actionTypes";

export default function createCourse({
  theme_name,
  adviser,
  order,
  place,
  start_date,
  task,
}) {
  return {
    types: [
      CREATE_COURSE_REQUEST,
      CREATE_COURSE_SUCCESS,
      CREATE_COURSE_FAILURE,
    ],
    promise: request
      .post(`${process.env.REACT_APP_API_URL}/api/v1/courses`)
      .set("Authorization", `Bearer ${sessionStorage.getItem("access_token")}`)
      .send({
        name: theme_name,
        adviser,
        order,
        place,
        start_date,
        task,
      }),
  };
}
