// Vendor
import React, { PropTypes, Component } from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from '../../../../../routex/lib/react';

// Component
import { LoginForm } from './../../../forms/auth';

// Actions
import { login } from './../../../actions/auth';

/**
 * Login
 * @class
 */
class Login extends Component {
    /**
     * Handle submit
     * @param data
     */
    handleSubmit(data) {
        const { login } = this.props;

        login(data.username, data.password);
    }

    /**
     * Render
     * @returns {XML}
     */
    render() {
        return (
            <div>
                <Helmet title={`${process.env.REACT_APP_TITLE} - Login`} />

                <div className="auth-form">
                    <LoginForm isLoading={this.props.loginLoading} onSubmit={this.handleSubmit.bind(this)} />

                    <div className="auth-form__link">
                        <Link to="/auth/forgot-password"><FormattedMessage id="FORGOT_PASSWORD" /></Link>
                    </div>
                </div>

                <div className="contact-block">
                    <p><FormattedMessage id="CONTACT_US_INFO" /></p>
                    <a href="mailto:support@businessbanking.academy">
                        <button className="contact-block__button">
                            <FormattedMessage id="CONTACT_US" />
                        </button>
                    </a>
                </div>
            </div>
        );
    }
}

// Prop Types
Login.propTypes = {
    loginLoading: PropTypes.bool,
    login: PropTypes.func.isRequired
};

// Connect
export default connect(
    (state) => ({
        loginLoading: state.auth.get('loginLoading')
    }),
    (dispatch) => (
        bindActionCreators({ login }, dispatch)
    )
)(Login);
