import request from 'superagent-bluebird-promise';
import {
    DELETE_QUESTION_REQUEST,
    DELETE_QUESTION_SUCCESS,
    DELETE_QUESTION_FAILURE
} from '../../constants/actionTypes';

/**
 * Delete question action
 *
 * @param{Number} id
 * @param{Number} questionId
 * @returns {{types: *[], promise: Request }}
 */

export default function deleteQuestion(id, questionId) {
    return {
        types: [DELETE_QUESTION_REQUEST, DELETE_QUESTION_SUCCESS, DELETE_QUESTION_FAILURE],
        promise: request
                    .del(`${process.env.REACT_APP_API_URL}/api/v1/course/${id}/question/${questionId}`)
                    .set('Authorization', `Bearer ${sessionStorage.getItem('access_token')}`),
        id, questionId
    };
}
