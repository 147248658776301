exports.__esModule = true;
exports.changeStart = changeStart;
exports.changeSuccess = changeSuccess;
exports.changeFail = changeFail;
exports.notFound = notFound;
exports.transitionTo = transitionTo;

const _actionTypes = require('./actionTypes');

function changeStart(currentRoute, nextRoute) {
  return {
    type: _actionTypes.ROUTE_CHANGE_START,
    route: currentRoute,
    nextRoute
  };
}

function changeSuccess(currentRoute) {
  return {
    type: _actionTypes.ROUTE_CHANGE_SUCCESS,
    route: currentRoute
  };
}

function changeFail(currentRoute, error) {
  return {
    type: _actionTypes.ROUTE_CHANGE_FAIL,
    route: currentRoute,
    error
  };
}

function notFound(path, query) {
  return {
    type: _actionTypes.ROUTE_NOT_FOUND,
    path,
    query
  };
}

function transitionTo(path) {
  const query =
    arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

  return {
    type: _actionTypes.TRANSITION_TO,
    pathname: path,
    query
  };
}
