import React, { PropTypes } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import { isBba, isRba } from "../../helpers";

const ProgressBar = (props) => {
  const progress = props.disabled
    ? 0
    : isRba() || isBba()
    ? Math.min(100, Number(props.progress))
    : props.progress;
  const maximum =
    props.maximum !== 0
      ? isBba() || isRba()
        ? Math.min(props.maximum, 100)
        : props.maximum
      : 120;

  if (props.table) {
    return (
      <div
        className={classNames(
          "progress-bar",
          props.disabled && "disabled",
          props.table && "in-table"
        )}
        style={{
          width: `calc(100% / ${props.courses?.size || 1})`,
        }}
      >
        <span className="progress-bar__small-info">{progress} b</span>
        <span
          className={classNames(
            "progress-bar__line",
            `line${props.number}`,
            props.disabled && "disabled"
          )}
        ></span>
        <span
          className={classNames(
            "progress-bar__inner-line",
            `line${props.number}`,
            props.disabled && "disabled"
          )}
          style={{ width: `calc(${(progress / maximum) * 100}% - 7px)` }}
        />
      </div>
    );
  }

  return (
    <div
      className={classNames("progress-bar", props.disabled && "disabled")}
      style={{
        width: `calc(100% / ${props.courses?.size || 1})`,
      }}
    >
      <span
        className={classNames(
          "progress-bar__line",
          `line${props.number}`,
          props.disabled && "disabled"
        )}
      ></span>
      <span
        className={classNames(
          "progress-bar__inner-line",
          `line${props.number}`,
          props.disabled && "disabled"
        )}
        style={{ width: `calc(${(progress / maximum) * 100}% - 7px)` }}
      />
      <span
        className={classNames("progress-bar__info", props.active && "active")}
      >
        <span className="desktop">
          <FormattedMessage
            id="MET"
            values={{
              progress: progress.toString(),
              maximum: maximum.toString(),
            }}
          />
        </span>
        <span className="mobile">
          {progress.toString()} / {maximum.toString()}
        </span>
      </span>
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  number: PropTypes.string.isRequired,
  active: PropTypes.bool,
  table: PropTypes.bool,
  disabled: PropTypes.bool,
  maximum: PropTypes.number.isRequired,
};

export default ProgressBar;
