import request from "superagent-bluebird-promise";
import {
  ARCHIVE_COURSE_REQUEST,
  ARCHIVE_COURSE_SUCCESS,
  ARCHIVE_COURSE_FAILURE,
} from "../../constants/actionTypes";

export default function createCourse(id) {
  return {
    types: [
      ARCHIVE_COURSE_REQUEST,
      ARCHIVE_COURSE_SUCCESS,
      ARCHIVE_COURSE_FAILURE,
    ],
    promise: request
      .del(`${process.env.REACT_APP_API_URL}/api/v1/course/${id}`)
      .set("Authorization", `Bearer ${sessionStorage.getItem("access_token")}`),
  };
}
