import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import { ButtonGroup } from './../../components';

import { renderInput } from './../../actions';

import { validate } from './validate';


let LoginForm = (props) => {
    const { handleSubmit, isLoading, loginError } = props;

    return (
        <form autoComplete="off" className="form" onSubmit={handleSubmit}>
            <input style={{ display: 'none' }} />
            <input type="password" style={{ display: 'none' }} />
            <Field name="username" component={renderInput} fieldError={loginError} />
            <Field name="password" type="password" component={renderInput} fieldError={loginError} />

            <ButtonGroup>
                <button type="submit" onClick={handleSubmit} disabled={isLoading}>
                    <FormattedMessage id={isLoading ? 'LOADING' : 'LOGIN'} />
                </button>
            </ButtonGroup>
        </form>
    );
};

LoginForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    loginError: PropTypes.bool
};

LoginForm = reduxForm({
    form: 'login',
    validate
})(LoginForm);

LoginForm = connect(
    state => ({
        loginError: state.auth.get('loginError')
    })
)(LoginForm);

export default LoginForm;
