import request from "superagent-bluebird-promise";
import {
  SET_SECTION_DATA_REQUEST,
  SET_SECTION_DATA_SUCCESS,
  SET_SECTION_DATA_FAILURE,
} from "../../constants/actionTypes";

export default function setSectionData(userId, courseId, sectionId, value) {
  return {
    types: [
      SET_SECTION_DATA_REQUEST,
      SET_SECTION_DATA_SUCCESS,
      SET_SECTION_DATA_FAILURE,
    ],
    promise: request
      .put(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/user/${userId}/course/${courseId}/section/${sectionId}?call=${Date.now()}`
      )
      .set("Authorization", `Bearer ${sessionStorage.getItem("access_token")}`)
      .send({
        value,
      }),
    userId,
    courseId,
    sectionId,
    value,
  };
}
