import request from 'superagent-bluebird-promise';
import {
    FETCH_USERS_REQUEST,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAILURE
} from '../../constants/actionTypes';

/**
 * Fetch users action
 *
 * @returns {{types: *[], promise: Request }}
 */

export default function fetchUsers() {
    return {
        types: [FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS, FETCH_USERS_FAILURE],
        promise: request
                    .get(`${process.env.REACT_APP_API_URL}/api/v1/users?call=${Date.now()}`)
                    .set('Authorization', `Bearer ${sessionStorage.getItem('access_token')}`)
    };
}
