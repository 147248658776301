import React, { Component, PropTypes } from "react";
import moment from "moment";
import { actions } from "../../../../../routex";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";

import AddUserModal from "./AddUserModal";
import { Button } from "./../../../components";
import { AdminTable } from "./../components";
import { ProgressBlock, RowBreak } from "./../../User/Main/components";

import Detail from "./subviews/Detail";

import { filterUsers } from "./../../../actions/user";
import { getUserData } from "./../../../helpers";
import { isRba } from "../../../helpers";

class Participants extends Component {
  constructor(props) {
    super(props);

    const allCourses = [];

    props.users.forEach((user) => {
      if (
        !allCourses.find(
          (course) => course.get("course_id") === user.get("selected_course")
        )
      ) {
        if (
          user
            .get("courses")
            .find(
              (course) =>
                course.get("course_id") === user.get("selected_course")
            )
        ) {
          allCourses.push(
            user
              .get("courses")
              .find(
                (course) =>
                  course.get("course_id") === user.get("selected_course")
              )
          );
        }
      }
    });

    this.state = {
      sortBy: "sort-1",
      filterBy: "null",
      allCourses: allCourses.sort((a, b) => {
        const first = a.get("start_date");
        const second = b.get("start_date");

        if (first < second) {
          return -1;
        }

        if (first > second) {
          return 1;
        }

        return 0;
      }),
    };
  }

  componentDidMount() {
    const { filterUsers } = this.props;
    filterUsers("");
  }

  createUser() {
    const { addModal } = this.context;

    addModal(<AddUserModal />);
  }

  sorted = (data) => {
    const { sortBy, filterByCourse } = this.state;
    const filtered =
      filterByCourse != null && filterByCourse !== ""
        ? data.filter(
            (item) => item.get("selected_course") === Number(filterByCourse)
          )
        : data;

    if (sortBy === "sort-1") {
      return filtered.sort((a, b) => {
        const first = a.get("name") || a.get("username");
        const second = b.get("name") || b.get("username");

        if (first < second) {
          return -1;
        }

        if (first > second) {
          return 1;
        }

        return 0;
      });
    } else if (sortBy === "sort-2") {
      return filtered.sort((a, b) => {
        const first = a.get("name") || a.get("username");
        const second = b.get("name") || b.get("username");

        if (first > second) {
          return -1;
        }

        if (first < second) {
          return 1;
        }

        return 0;
      });
    } else if (sortBy === "sort-3") {
      return filtered.sort((a, b) => {
        let total1 = 0;
        let total2 = 0;

        a.get("graph").map((item) => (total1 += item.get("value")));

        b.get("graph").map((item) => (total2 += item.get("value")));

        if (total1 < total2) {
          return 1;
        }

        if (total1 > total2) {
          return -1;
        }

        return 0;
      });
    } else if (sortBy === "sort-4") {
      return filtered.sort((a, b) => {
        let total1 = 0;
        let total2 = 0;

        a.get("graph").map((item) => (total1 += item.get("value")));

        b.get("graph").map((item) => (total2 += item.get("value")));

        if (total1 > total2) {
          return 1;
        }

        if (total1 < total2) {
          return -1;
        }

        return 0;
      });
    }

    return filtered;
  };

  selectComponent = () => {
    return (
      <div className="select-component-wrapper">
        <p>
          <FormattedMessage id="SORT_BY" />:{" "}
          <select
            name="select-sort-by"
            id="select-sort-by"
            className="select-component"
            onChange={(e) => {
              this.setState({
                sortBy: e.target.value,
              });
            }}
          >
            <option value="sort-1">Abecedne A-Z</option>
            <option value="sort-2">Abecedne Z-A</option>
            <option value="sort-3">Body najviac</option>
            <option value="sort-4">Body najmenej</option>
          </select>
        </p>
      </div>
    );
  };

  filterComponent = () => {
    return (
      <div className="filter-component-wrapper">
        <p>
          <FormattedMessage id="FILTER_BY_ACTIVE_COURSE" />:{" "}
          <select
            name="select-sort-by"
            id="select-sort-by"
            className="select-component"
            onChange={(e) => {
              this.setState({
                filterByCourse: e.target.value,
              });
            }}
          >
            <option value="">Všetky</option>
            {this.state.allCourses.map((course) => (
              <option value={course.get("course_id")}>
                {course.get("name")} -{" "}
                {moment(course.get("start_date")).format("DD.MM.YYYY")}
              </option>
            ))}
          </select>
        </p>
      </div>
    );
  };

  renderTable() {
    const { users, transitionTo, filterValue } = this.props;
    const filtered = filterValue
      ? users.filter((u) => {
          const userData = getUserData(u);
          const fullName = `${userData.name}${userData.username}`;

          return fullName.toLowerCase().indexOf(filterValue) > -1;
        })
      : users;

    return (
      <div className="table">
        {filtered.length > 0 ? (
          this.sorted(filtered).map((user, i) => {
            let total = 0;
            user.get("graph").map((item) => (total += item.get("value")));

            return (
              <div className="tr" key={i}>
                <div className="td">
                  <button
                    onClick={() => {
                      transitionTo(
                        `/admin/participants/detail/${user.get("id")}`
                      );
                    }}
                    className="edit-button"
                  />
                </div>
                <div className="td">
                  <strong>{getUserData(user).name}</strong> <br />
                  {user.get("username")}
                </div>
                <div className="td">
                  <ProgressBlock
                    admin
                    table
                    courses={user.get("courses")}
                    graph={user.get("graph")}
                  />
                </div>
                <div className="td">
                  <FormattedMessage id="TOTAL_SCORE" />
                  <br />
                  <span className="progress">
                    {isRba() ? Math.min(500, total) : total} b
                  </span>
                </div>
                <div className="mobile-progress">
                  <ProgressBlock
                    admin
                    table
                    courses={user.get("courses")}
                    graph={user.get("graph")}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <p className="no-users">
            <FormattedMessage id="NO_USERS" />
          </p>
        )}
      </div>
    );
  }

  renderSuperiorTable() {
    const { user, users, transitionTo, filterValue } = this.props;
    const filtered = filterValue
      ? users.filter((u) => {
          const userData = getUserData(u);
          const fullName = `${userData.name}${userData.username}`;

          return fullName.toLowerCase().indexOf(filterValue) > -1;
        })
      : users;

    const myTeam = filtered.filter(
      (u) => u.get("superior") && u.get("superior").get("id") === user.get("id")
    );
    const allUsers = filtered.filter(
      (u) =>
        !u.get("superior") || u.get("superior").get("id") !== user.get("id")
    );

    return (
      <div>
        <RowBreak noPaddingTop>
          <FormattedMessage id="MY_TEAM" />
        </RowBreak>
        <div className="table">
          {myTeam.length > 0 ? (
            this.sorted(myTeam).map((user, i) => {
              let total = 0;
              user.get("graph").map((item) => (total += item.get("value")));

              return (
                <div className="tr" key={i}>
                  <div className="td">
                    <button
                      onClick={() => {
                        transitionTo(
                          `/admin/participants/detail/${user.get("id")}`
                        );
                      }}
                      className="edit-button"
                    />
                  </div>
                  <div className="td">
                    <strong>{getUserData(user).name}</strong> <br />
                    {user.get("username")}
                  </div>
                  <div className="td">
                    <ProgressBlock
                      admin
                      table
                      courses={user.get("courses")}
                      graph={user.get("graph")}
                    />
                  </div>
                  <div className="td">
                    <FormattedMessage id="TOTAL_SCORE" />
                    <br />
                    <span className="progress">
                      {isRba() ? Math.min(500, total) : total} b
                    </span>
                  </div>
                  <div className="mobile-progress">
                    <ProgressBlock
                      admin
                      table
                      courses={user.get("courses")}
                      graph={user.get("graph")}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <p className="no-users">
              <FormattedMessage id="NO_USERS" />
            </p>
          )}
        </div>
        <RowBreak noPaddingTop>
          <FormattedMessage id="ALL_USERS" />
        </RowBreak>
        <div className="table">
          {allUsers.length > 0 ? (
            this.sorted(allUsers).map((user, i) => {
              let total = 0;
              user.get("graph").map((item) => (total += item.get("value")));

              return (
                <div className="tr" key={i}>
                  <div className="td">
                    <button
                      onClick={() => {
                        transitionTo(
                          `/admin/participants/detail/${user.get("id")}`
                        );
                      }}
                      className="eye-button"
                    />
                  </div>
                  <div className="td">
                    <strong>{getUserData(user).name}</strong> <br />
                    {user.get("username")}
                  </div>
                  <div className="td">
                    <ProgressBlock
                      admin
                      table
                      courses={user.get("courses")}
                      graph={user.get("graph")}
                    />
                  </div>
                  <div className="td">
                    <FormattedMessage id="TOTAL_SCORE" />
                    <br />
                    <span className="progress">
                      {isRba() ? Math.min(500, total) : total} b
                    </span>
                  </div>
                  <div className="mobile-progress">
                    <ProgressBlock
                      admin
                      table
                      courses={user.get("courses")}
                      graph={user.get("graph")}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <p className="no-users">
              <FormattedMessage id="NO_USERS" />
            </p>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { role, filterUsers } = this.props;

    return (
      <div className="participants">
        <AdminTable
          heading="PARTICIPANTS"
          search={filterUsers}
          buttonGroup={
            role === "lector" ? (
              <Button small onClick={this.createUser.bind(this)}>
                <img
                  src={`/${process.env.REACT_APP_TYPE}/assets/icons/add.png`}
                  alt="add"
                />
                <FormattedMessage id="ADD_USER" />
              </Button>
            ) : null
          }
        >
          {this.selectComponent()}
          {this.filterComponent()}
          {role === "superior"
            ? this.renderSuperiorTable.bind(this)()
            : this.renderTable.bind(this)()}
        </AdminTable>
      </div>
    );
  }
}

Participants.propTypes = {
  users: PropTypes.array.isRequired,
  transitionTo: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  filterUsers: PropTypes.func.isRequired,
  filterValue: PropTypes.string,
};

Participants.contextTypes = {
  addModal: PropTypes.func.isRequired,
};

Participants.Detail = Detail;

export default connect(
  (state) => ({
    role: state.users.get("user").get("role"),
    user: state.users.get("user"),
    filterValue: state.users.get("filterValue"),
    users: state.users.get("users").toArray(),
  }),
  (dispatch) => {
    const transitionTo = actions.transitionTo;
    return bindActionCreators({ transitionTo, filterUsers }, dispatch);
  }
)(Participants);
