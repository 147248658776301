import React, { Component, PropTypes } from "react";
import classNames from "classnames";
import { validate } from "./validate";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import { reduxForm, Field, change } from "redux-form";
import Editor from "draft-js-editor";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

import { Button } from "./../../../components";

import { renderInput } from "./../../../forms/actions";
import { normalizeError } from "./../../../helpers";

const form = "editAdminCourse";

class EditCourseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      editorState: EditorState.createEmpty(),
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const { change, courseId, courses } = this.props;

    if (courseId != null) {
      const editedCourse = courses.get(courseId);

      change(form, "theme_name", editedCourse.get("name"));
      change(form, "adviser", editedCourse.get("adviser"));
      change(form, "place", editedCourse.get("place"));
      change(form, "start_date", editedCourse.get("start_date"));
      change(form, "task", editedCourse.get("task"));

      const blocksFromHTML = convertFromHTML(editedCourse.get("task") || "");
      const state = ContentState.createFromBlockArray(blocksFromHTML);
      this.setState({ editorState: EditorState.createWithContent(state) }); // eslint-disable-line
    }
  }

  onChange(editorState) {
    const { change } = this.props;
    const contentState = editorState.getCurrentContent();
    const html = draftToHtml(convertToRaw(contentState));
    this.setState({ editorState });
    change(form, "task", html);
  }

  render() {
    const { handleSubmit, isLoading, editError, courseId, courses } =
      this.props;
    const { editorState, active } = this.state;

    return (
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div style={{ margin: "3em 0" }}>
          <Field
            name="theme_name"
            visibleError
            component={renderInput}
            type="input"
          />
          <Field
            name="adviser"
            visibleError
            component={renderInput}
            type="input"
          />
          <Field
            name="place"
            visibleError
            component={renderInput}
            type="input"
          />
          {courseId == null ? (
            <Field
              name="order"
              visibleError
              component={renderInput}
              type="number"
              min={1}
              max={Number(
                (courses?.filter((course) => course.get("active")).size || 0) +
                  1
              )}
            />
          ) : null}
          <Field
            name="start_date"
            component={renderInput}
            type="datetime-local"
          />

          <h4>
            <FormattedMessage
              id={courseId == null ? "NEW_THEME" : "ASSIGNMENT"}
            />
          </h4>
          <Editor
            placeholder={!active ? "Obsah zadania" : null}
            editorState={editorState}
            onChange={this.onChange}
            className={classNames("editor", active && "active")}
            onFocus={() => {
              this.setState({ active: true });
            }}
            onBlur={() => {
              this.setState({ active: false });
            }}
          />
        </div>
        <div className="error">
          {editError.status === 422 && (
            <span>
              <FormattedMessage id={normalizeError(422)} />
              <br />
              <FormattedMessage id="DATE_INFO" />
              <br />
              <FormattedMessage id="ORDER_INFO" />
            </span>
          )}
          {editError.status &&
            editError.status !== 422 &&
            editError.body.error_code !== 10 && (
              <span>{editError.toString()}</span>
            )}
          {editError.status &&
            editError.status !== 422 &&
            editError.body.error_code === 10 && (
              <FormattedMessage id="LOCKED_COURSE" />
            )}
        </div>
        <div style={{ textAlign: "center" }}>
          <Button type="button" onClick={handleSubmit} disabled={isLoading}>
            <FormattedMessage
              id={isLoading ? "LOADING" : courseId == null ? "CREATE" : "SAVE"}
            />
          </Button>
        </div>
      </form>
    );
  }
}

EditCourseForm.propTypes = {
  isLoading: PropTypes.bool,
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  courseId: PropTypes.number,
  courses: PropTypes.object,
  editError: PropTypes.object,
};

EditCourseForm = connect(
  (state, ownProps) => ({
    isLoading: state.users.get("editCourseLoading"),
    editError: state.users.getIn(["editCourseError", ownProps.courseId]) || {},
  }),
  (dispatch) => bindActionCreators({ change }, dispatch)
)(EditCourseForm);

export default reduxForm({
  form,
  validate,
})(EditCourseForm);
