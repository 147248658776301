import React from 'react';

import { UserInfoTable, MainTable } from './components';

const Main = () => ((
    <div>
        <UserInfoTable />
        <MainTable />
    </div>
));

export default Main;
