export default function normalizeError(status) {
    switch (status) {
        case 1:
            return 'BAD_ROLE';
        case 2:
            return 'USER_NOT_IN_COURSE';
        case 3:
            return 'BAD_FILE';
        case 4:
            return 'ALREADY_GENERATED';
        case 7:
            return 'NO_QUESTIONS';
        case 8:
            return 'USER_NOT_EXIST';
        case 11:
            return 'USER_ALREADY_EXIST';
        case 422:
            return 'REQUIRED_ALL_FIELDS';
        default:
            return 'ERROR';
    }
}
