/**
 * Is simple object?
 *
 * @param {*} obj
 * @returns {boolean}
 */
function isSimpleObject(obj) {
    if (typeof obj === 'object') {
        if (obj !== null && !(obj instanceof Promise) && !(obj instanceof Date)) {
            return true;
        }
    }

    return false;
}

/**
 * Flattens object to one level
 *
 * @param {*} obj
 * @returns {Object.<string, *>}
 */
export default function flattenObjectKeys(obj) {
    if (!isSimpleObject(obj)) {
        return obj;
    }

    return Object.keys(obj).reduce((acc, key) => {
        if (!isSimpleObject(obj[key])) {
            acc[key] = obj[key]; // eslint-disable-line
            return acc;
        }

        const nested = flattenObjectKeys(obj[key]);

        Object.keys(nested).forEach(nestedKey => {
            acc[`${key}.${nestedKey}`] = nested[nestedKey]; // eslint-disable-line
        });

        return acc;
    }, {});
}
